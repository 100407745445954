import Header from "../../components/header";
import Landing from "../BeeteeLanding";
import ServicePage from "../ServicePage";
import News from "../News";
import Meet from "../Meet";
import Contact from "../contact";
import Foother from "../../components/foother";
import Cirqle from "../../components/bluehref";
import './style.scss';


function Main() {
    return (
        <div className="main">
   
            <Landing />
            <ServicePage />
            <News />
            <Meet />
            <Contact />
            <Foother />
          

        </div>
    )
}

export default Main;