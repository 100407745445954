import "./style.scss";
import map from '../../assets/images/maps.png'
import MySvg from "./map/map";
import { useState } from "react";
import { Link } from "react-router-dom";
import { API_ENDPOINT } from "../../config";



function Contact() {
  const [fullName, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState();
  const [emailError, setEmailError] = useState();

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    if (value === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);

    if (value === "" || !value.includes("@")) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleclick = () => {
   
    if(nameError === false && emailError === false  && email !== '' && fullName !== ''){
        const obj = {
        fullName: fullName,
        email: email,
        message: message,
        ipAddress: 'null',
        city: 'erevan'
      };
  
      setTimeout(() => {
        setName('')
        setEmail('')
        setMessage('')
      }, 1000);

      let raw = JSON.stringify(obj);
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow'
      };

      fetch(`${API_ENDPOINT}/api/ContactUs `, requestOptions)
        .then(response => response.text())
        .then(send => console.log(send))
      }
      else {
        setEmailError(true);
        setNameError(true);
      }
  }



  
  return (
    <section className="L-contac-container G-position-sticky sizing section G-disable-sticky" id="contact">
      <div className="G-container  ">
        <div className="L-contact-wrapper G-flex  ">
          <div className="L-map-container">
            <div className="L-map G-background-cover" >
              <Link className="hreff" target='_blank' to="https://www.google.com/maps/place/1+Kajaznuni+St,+Yerevan+0070/@40.1711894,44.5173014,17z/data=!3m1!4b1!4m6!3m5!1s0x406abcf5084ec32b:0xfb4b8df4892b52e7!8m2!3d40.1711853!4d44.5194901!16s%2Fg%2F11cncrcz3t"><MySvg className='My-Map' /></Link>
            </div>
          </div>
          <div className="L-contact G-flex G-flex-column ">
            <div className="L-contact-txt">
              <div className="L-animation-con G-flex G-align-center">
                <div className="L-logo-box-contact ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.608" height="25.312"><path d="M20.608 12.463v.34c0 .366-.134.719-.388.987l-2.344 2.499-9.922 10.565a1.428 1.428 0 0 1-.628.388c-.134.05-.276.07-.424.07h-.007c-.797 0-1.44-.649-1.44-1.44v-8.144c0-.797-.642-1.44-1.44-1.44H1.44c-.79 0-1.44-.642-1.44-1.44v-.338c0-.367.141-.72.395-.988l2.35-2.498 2.979-3.17L12.668.46c.275-.29.65-.459 1.052-.459.79 0 1.44.65 1.44 1.44v8.144c0 .797.642 1.44 1.44 1.44h2.568c.797 0 1.44.642 1.44 1.44Z" fill="#f9c800" fillRule="evenodd" data-name="Path 6" /></svg>
                </div>
                <p className="Pages-greeting"> Let's talk!</p>
              </div>
              <h2 className="Pages-Title" style={{ marginTop: '5px', marginBottom: '5px' }}>Contact Us</h2>
              <p className="Pages-description" style={{ marginBottom: '15px' }}>We’re here to help, whether you’re looking for IT services and consulting or have a specific question about our company.</p>
            </div>
            <div className="L-contact-inp G-flex G-justify-between">
              <div className="inp-con"><span className="txt" >Full Name</span> <br /><input type="text" placeholder="E.g John Smith" className={nameError ? 'errorName' : 'contact-input'} value={fullName} onChange={handleNameChange} /></div>
              <div className="inp-con"><span className="txt" >Email</span> <br /> <input type="text" placeholder="E.g JohnSmith@gmail.com" className={emailError ? 'errorName':"contact-input"} value={email} onChange={handleEmailChange} /></div>
            </div>
            <div className="L-textarea">
              <span className="txt">Message</span><br />
              <textarea className="input" placeholder="E.g your text heare" id="w3review" name="w3review" value={message} onChange={(e) => setMessage(e.target.value)} />
            </div>
            <div className="L-button G-flex G-justify-between">
              <div className="dat">
                <p className="num">  +374-(98)-130-810</p><br />
                <p className="num" > Yerevan, Kajaznuni Street 1</p>
              </div>
              <button onClick={handleclick} className="send-button">SEND</button>


            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Contact;