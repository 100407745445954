import './style.scss';
import myimage from '../../../assets/images/newsImages/josh-nuttall-XV.png';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ScrollToTop from '../../../components/scrollToTop';
import backArrow from '../../..//assets/images/newsImages/back-arrow.svg'
import { API_ENDPOINT } from '../../../config';

function InsideNews() {
  const [html, setHTML] = useState({ __html: "" });
  const [data, setData] = useState({});
  const [dataImage, setDataImage] = useState();
  const [userImage, setUserImage] = useState();
  const { path } = useParams();

  useEffect(() => {
    async function createMarkup() {
      let response;
      response = await fetch(`${API_ENDPOINT}/api/blog/${path}`)
      const backendHtmlString = await response.json()

      setData(backendHtmlString)

      fetch(`${API_ENDPOINT}${backendHtmlString.imageUrl}`)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => setDataImage(url))

      fetch(`${API_ENDPOINT}${backendHtmlString.userImageUrl}`)
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(url => setUserImage(url))
      .catch(error => console.log(error));
      return { __html: backendHtmlString.body};
    }
    createMarkup().then(result => setHTML(result));



  }, []);



  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramValue = params.get('param');
  return (
    <section className="L-inside-container ">
      <ScrollToTop />
      <div className="L-inside-news-container">
        <div className="G-container">
          <div className="L-inside-wrapper">
            <div className="L-inside-menu">
              <Link to={paramValue === 'main' ? '/' : '/Blog'} className='back-button' ><img className='back-arrow' src={backArrow} alt="" /> Back </Link>

            </div>
            <div className="L-inside-body">
              <Link to={paramValue === 'main' ? '/' : '/Blog'} className='back-button' ><img className='back-arrow' src={backArrow} alt="" />  </Link>

              <div className="image-box">
                <img src={dataImage} alt="" className='myimage' />
              </div>
              <div className="L-inside-data">
                <div className="title-box">
                  <div className="cirqle-avatar">
                    <img src={userImage} alt="" className='cirqle-image' />
                  </div>
                  <div className="title">
                    <h3>{data.author}</h3>
                    <p className='duration'>{data.readDuration}</p>
                  </div>

                </div>
                <h2 className='titl'></h2>
                <div className='dangerousinnerhtml' dangerouslySetInnerHTML={html} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InsideNews;



