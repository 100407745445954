import "./style.scss";
import blueLogo from "../../assets/images/Beetee_logo.svg";
import { useState,useEffect } from "react";
import loadingAnimationGif from '../../assets/gifs/logo.gif'


function Welcome(){
  const [change , setChange] = useState(false)


  setTimeout(() => {
setChange(true)
  }, 1000);

  return(
    <>
    
   <section className="repple">
     <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

   </section>

    </>
    )
}
export default Welcome;
