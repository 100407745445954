import './style.scss';
import myimage from '../../../assets/images/newsImages/josh-nuttall-XV.png';
import { Link} from 'react-router-dom';
import ScrollToTop from '../../../components/scrollToTop';
import backArrow from '../../..//assets/images/newsImages/back-arrow.svg'
import Cirqle from '../../../components/bluehref';
import { useState, useEffect } from 'react';
import { API_ENDPOINT } from '../../../config';



function InsideNews() {
  const [data, setHTML] = useState([]);
  const [count, setCount] = useState(0);

 
  useEffect(() => {
    const handleScroll = () => {
      const scrollableElement = document.documentElement;
      const scrollTop = scrollableElement.scrollTop;
      const scrollHeight = scrollableElement.scrollHeight;
      const clientHeight = scrollableElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
       setCount(()=>{
        return count + 3
       })
  
      }
    };



    async function createMarkup() {
      let response;
      response = await fetch(`${API_ENDPOINT}/api/blog?take=3&skip=0`)
      const backendHtmlString = await response.json()
      const myBodyHeight = document.getElementById('test')
      return backendHtmlString;
    }
    createMarkup().then(result => setHTML((prevstate)=> [...prevstate,...result]));

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <section className="L-blog-container  " id='Blog'>
      <ScrollToTop />
      <div className="G-container">
        <section className="inside-wrapper-news G-flex ">
          <div className="L-menu">
            <div className="text-container G-flex G-flex-column G-justify-between">
              <Link to='/' style={{ color: 'black', fontFamily: "montserrat" }} className='cursor G-flex G-align-center'>
                <img src={backArrow} alt="" className='back-arrow' />
                <p> Back</p>
              </Link>
              <div>
                <p className='Pages-greeting'>Tech insights</p>
                <h2 className="Pages-Title">News</h2>
                <p className="Pages-description">Discover practical solutions for common IT challenges.</p>

              </div>
            </div>
          </div>
          <div className="L-data-body">
            {data.map((el,index) => {
             
              return (
                <div className={index % 2 ==! 0 ? "flexebl G-flex G-justify-end":"flexebl"}>
                     <div className="L-body-box" key={el.key} id={el.id}>
                  <div className="L-box">
                    <div className="imgs  G-flex G-align-end"  style={{ backgroundImage: `url('https://beeteeapi.azurewebsites.net/${el.imageUrl}')` }} >
                      <Cirqle className='start-cirqle' path={el.path}   />
                    </div>
                    <p className="L-box-data">{el.readDuration}</p>
                    <h2 className='L-box-title' >{el.title}</h2>
                    <p className='Pages-description'>{el.description} </p>
                  </div>
                </div>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    </section>
  )
}

export default InsideNews;



{/* <div className='wrapper' dangerouslySetInnerHTML={{ __html: el ? el.innerHTML : '' }} />   */ }