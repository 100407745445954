import React, { useState, useEffect } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify'
import './style.scss'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { API_ENDPOINT } from '../../config';


function AdminPage() {
    const [body, setConvertedContent] = useState(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [sinIn, setSinIn] = useState(false)


    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    function createMarkup(html) {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_ENDPOINT}/api/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ login: `${email}`, password: `${password}` })
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data)
                setAccessToken(data.accessToken);
                setError(null);
                console.log(accessToken, 'token');
                console.log("sendet");
                setTimeout(() => {
                    setSinIn(true)
                }, 1000);
            } else {
                setError(data.error);
                console.log(error);
                setAccessToken(null);
            }
        } catch (err) {
            console.error(err);
            setError('An error occurred. Please try again.');
            setAccessToken(null);
        }
    };


    const sendButton = (e) => {

        let raw = JSON.stringify({
            "description": "Lorem Ipsum Lorem Ipsum Lorem Ipsum",
            "position": "string",
            "email": "tirbaghdasaryan0808@gmail.com",
            "title": "string",
            "body": body,
            "author": "Tiran Baghdasaryan",
            "readDuration": "1 min",
        });

        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_ENDPOINT}/api/blog`, requestOptions)
            .then(response => response.text())

        // fetch(`https://beeteeapi.azurewebsites.net/api/blog/4ea44080-c017-4482-990b-f5c30d2edc20`, {
        //     method: "DELETE",
        //     headers: {
        //       "Content-Type": "application/json",
        //       'Authorization': `Bearer ${accessToken}`
        //     },
        //   })
        //     .then((response) => {
        //       if (!response.ok) {
        //         throw new Error("Failed to delete item");
        //       }
        //       return response.json();
        //     })
        //     .then((data) => {
        //       console.log(data);
        //       // Do something with the response data
        //     })

        setConvertedContent(null)
    };



    return (

        <>
            {sinIn ? 
                <div className="menu  G-flex  G-align-center G-flex-column">
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                    />
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(body)}>
                    </div>
                    <button className='Send' onClick={() => sendButton()} >Send Data</button>
                </div>
                :
                <div className="sin_in G-flex G-justify-center G-align-center">
                <div className="authorization-container">
                    <h1>sin in</h1>
                    <form className='send_form' onSubmit={handleSubmit} >
                        <div>
                            <label htmlFor="email">Email:</label>
                            <input type="text" id="email" className='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div>
                            <label htmlFor="password">Password:</label>
                            <input type="password" id="password" className='password' value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        <button type="submit" className='submit'>Login</button>
                    </form>
                </div>
            </div>
            }
        </>

    )
}

export default AdminPage;


