import { useState,useEffect } from "react";

export const  useBottomActiveId = ()=>{
    const [bottomActiveId, setActiveId] = useState('');

    useEffect(() => {
      const handleScroll = () => {
        const sections = document.querySelectorAll('section');
        const scrollTop = window.pageYOffset;
    
        sections.forEach(section => {
       
          if (section.offsetTop - section.offsetHeight + 70 <= scrollTop  ) {
            setActiveId(section.id);
          }
        });
      };
    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    return bottomActiveId    
}