import "./style.scss";
import { servicedata } from "./service-data";
import fleshImage from '../../assets/images/servicePage/background_element.svg';
import { useState } from "react";
import Modal from "../../components/modal";


function ServicePage() {
  const [flippedId, setFlippedId] = useState(-1);

  const handleclick = (id) => {

    if(flippedId !== id){
      servicedata[id].visible = true;
      
      setFlippedId(id);
    }
    if(flippedId === id){
      setFlippedId(-1);
      servicedata[id].visible = false;
    }
    if(flippedId !== -1){
      servicedata[flippedId].visible = false;
    }
    if(flippedId === undefined){
      servicedata[flippedId].visible = false;
 
    }
    if(servicedata[id].visible === true){
      document.body.style.overflow='hidden'
    }else{
      document.body.style.overflow='visible'
    }


  }




  return (
    <>
      <section className="L-service-container G-flex " id='service' >
      <div className="background-icon-container" style={{ backgroundImage: `url(${fleshImage})` }} >
  
      <div className="G-container  ">
          <div className="L-service-wrapper G-flex " >
            <div className="L-service-left-box G-flex ">
              <div className="L-text-container-service G-flex ">
                <div className="L-logo-container-service G-flex  ">
                  <div className="L-logo-box-service ">
                    <svg xmlns="http://www.w3.org/2000/svg" ><path d="M20.608 12.463v.34c0 .366-.134.719-.388.987l-2.344 2.499-9.922 10.565a1.428 1.428 0 0 1-.628.388c-.134.05-.276.07-.424.07h-.007c-.797 0-1.44-.649-1.44-1.44v-8.144c0-.797-.642-1.44-1.44-1.44H1.44c-.79 0-1.44-.642-1.44-1.44v-.338c0-.367.141-.72.395-.988l2.35-2.498 2.979-3.17L12.668.46c.275-.29.65-.459 1.052-.459.79 0 1.44.65 1.44 1.44v8.144c0 .797.642 1.44 1.44 1.44h2.568c.797 0 1.44.642 1.44 1.44Z" fill="#f9c800" fillRule="evenodd" data-name="Path 6" /></svg>
                  </div>
                  <p className="L-s Pages-greeting ">See What We Do</p>
                </div>
                <h2 className="Pages-Title">Our Services</h2>
                <p className="L-service-description Pages-description">We offer top-notch IT services to help businesses improve their operations and achieve their goals. Our experienced team uses the latest technologies to provide high-quality solutions that are customized to meet the unique needs of our clients. We offer UI/UX design, custom product development, strategic staffing, and IT consulting services that are flexible and scalable. Our services are designed to fit your specific requirements, ensuring that you receive the support you need. Whether you need assistance with creating a user-friendly interface, developing a custom software solution, finding top IT talent, or consulting on your IT strategy, we've got you covered.</p>

              </div>
            </div>
            <div className="L-service-right-box G-flex "   >
              {servicedata.map((el => {
                return (

                  <div className='L-card-hover-box' tabIndex='0' key={el.id} >
                    <div className={`card ${el.visible ? 'flipped' : ''}`}  onClick={() => handleclick(el.id)} key={el.id} >
                      <div className="front">
                        <div className='L-cube-back-side-icon'>{el.img}</div>
                        <h2 className="L-cube-title">{el.title}</h2>
                      </div>
                    
                    </div>
                    {flippedId === el.id && 
                   <Modal 
                   onClick={() => setTimeout(()=>{
                    handleclick(el.id)
                   },400)}
                   onButtonClick={() => setTimeout(()=>{
                    handleclick(el.id)
                   },400)}
                    src={el.img}
                    content={el.description}
                    title = {el.title}
                    />}
                  </div>
                )
              }))}
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
}

export default ServicePage;