import './map.css'
import WOW from 'wowjs';
import React, { useRef, useState, useEffect } from 'react';

function MySvg({className}) {

	const ref = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(entry.target);
				}
			},
			{
				rootMargin: '0px',
				threshold: 0.2,
			}
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, []);

	return (
		 <div ref={ref}>
			{isVisible &&
			(<svg className={className}  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			  viewBox="0 0 777.8 763.1" style={{ enableBackground: 'new 0 0 777.8 763.1',width: '100%', height: '100%' }} xmlSpace="preserve">
			<g>
				<g>
					<path className="st43 WOW IWcSNwFC_0" d="M396,291l31.1,90.7c1.4,4.1,5.9,6.2,9.9,4.8v0c4.1-1.5,8.6,0.7,10,4.9l38.4,117.4c1.3,4.1,5.8,6.3,9.9,4.9
			l64-22c4.1-1.4,6.2-5.9,4.8-9.9l-43.8-122.2c-1.5-4.1-5.9-6.1-10-4.6l-7.7,2.9c-4.1,1.5-8.7-0.7-10.1-4.9l-27.4-85
			c-1.3-4.1-5.7-6.3-9.8-5l-54.3,18.3C396.7,282.5,394.6,286.9,396,291z"></path>
					<path className="st43 WOW IWcSNwFC_1" d="M388.8,385.8l-24.2-62.3c-1.5-3.9-5.8-5.9-9.7-4.5l-20.7,7.2c-4.1,1.4-6.3,6-4.7,10.1l24.1,61.9
			c1.5,3.8,5.7,5.8,9.6,4.5L384,396C388.2,394.6,390.4,390,388.8,385.8z"></path>
					<path className="st43 WOW IWcSNwFC_2" d="M612,72.4l40.9,84.5c1.9,4,0.2,8.7-3.9,10.5L407.9,271.7c-3.8,1.6-8.1,0-10-3.6l-37.9-75
			c-2-3.8-0.4-8.5,3.5-10.4L600.3,66.1c0.6-0.3,1.3-0.5,2-0.7"></path>
					<path className="st43 WOW IWcSNwFC_3" d="M735.2,308.4L731.8,301.1L632.3,88.4"></path>
					<path className="st43 IWcSNwFC_4" d="M456.1,746l-42.8-123.1c-3-8.8,1.9-18.3,10.9-20.9c35.5-10,117.8-33.3,142.3-40.8
			c17.8-5.5,32.2-21.6,29.5-43.4c-4-32.3-34.9-116.2-37.1-125c-4.3-17.3-10.2-27.2-21.1-24.1c-9.2,2.6-9.2,11.9-7.4,19.7
			c0,0,0,0,0,0c1.1,5,3,9.3,3.7,10.7c0.2,0.4,0.3,0.8,0.5,1.2c2.3,6.3,19.1,53.4,28.9,87.2c1.1,3.7,2.1,7.2,2.9,10.4
			c8.8,32.8-13.5,37.6-17.2,38.2c-0.5,0.1-1,0.2-1.4,0.3l-136,34.3c-8.4,2.1-16.9-2.7-19.4-11L292,227.1c-2.3-7.5,1.2-15.5,8.1-19.1
			L591.9,58.6"></path>
					<path className="st43 WOW IWcSNwFC_5" d="M227.9,35.8l47.6,133c3.3,9.1,13.7,13.3,22.4,9.1L500,78.2c7.3-3.6,10.8-12.1,8.1-19.8l-15.5-44.6h0"></path>
					<path className="st43 WOW IWcSNwFC_6" d="M202.3,49.2l48.5,137.4c3.1,8.8-1.9,18.4-10.9,20.9L29.3,266.2v0"></path>
					<path className="st43 WOW IWcSNwFC_7" d="M21.9,294.4l226.3-61.6c8.6-2.3,17.5,2.7,19.8,11.4L327,460.7c2.2,8.2-2.3,16.7-10.3,19.5L65.6,567.4"></path>
					<path className="st43 IWcSNwFC_8" d="M77.4,586.1l245.5-86.8c8.5-3,17.7,1.5,20.7,10l21.7,63c2.9,8.5-1.6,17.7-10.1,20.6l-208.2,70.5"></path>
					<path className="st43 IWcSNwFC_9" d="M164.6,677.4L366,611.6c8.4-2.7,17.4,1.7,20.3,10l44.7,128"></path>
					<path className="st43 IWcSNwFC_10" d="M218.5,411l30.9-10.8c4-1.4,8.4,0.7,9.8,4.7l21.1,58.1c1.5,4-0.6,8.4-4.6,9.9l-34.8,13
			c-4.2,1.6-8.9-0.8-10.2-5.1l-17.2-60.3C212.5,416.5,214.6,412.3,218.5,411z"></path>
					<path className="st43 IWcSNwFC_11" d="M189.9,412.1l8.7,23.4c1.5,4.2-0.7,8.8-5,10.1L59.5,486.7c-3.9,1.2-8-0.8-9.5-4.6l-7.5-19.2
			c-1.6-4.1,0.5-8.7,4.7-10.1l132.9-45.3C184.1,406.2,188.4,408.2,189.9,412.1z"></path>
					<path className="st43 IWcSNwFC_12" d="M18,315.3l135.6-37.1c7.3-2,14.7,2.6,16.1,10l9.4,48.3c1.3,6.6-2.7,13-9.1,14.9L13.7,397.2"></path>
					<path className="st43 IWcSNwFC_13" d="M61.7,190.7c0.8,0.8,1.4,1.8,1.7,3l11.9,38.2c1.3,4.1-1,8.4-5.1,9.7l-23.5,7.1c-4,1.2-8.2-1-9.5-4.9"></path>
					<path className="st43 IWcSNwFC_14" d="M125.6,107.5l32.5,91.9c1.4,4.1-0.7,8.6-4.9,9.9l-51.2,17c-4,1.3-8.2-0.8-9.7-4.7L73.7,171"></path>
					<path className="st43 IWcSNwFC_15" d="M262.2,116.8l15.5,44.9c1.4,3.9,5.6,6.1,9.5,4.9l41.4-12.4c4.2-1.3,6.5-5.8,5.1-9.9l-16.5-47.5
			c-1.4-4.1-5.9-6.2-10-4.7L266.9,107C262.9,108.4,260.9,112.8,262.2,116.8z"></path>
					<path className="st43 IWcSNwFC_16" d="M252.4,34.5l23.3-6.6c4-1.1,8.2,1.1,9.5,5.1l14.6,46.7c1.2,3.8-0.7,7.9-4.4,9.5l-20.5,8.4
			c-4.1,1.7-8.7-0.4-10.2-4.5l-17.5-48.5C245.7,40.4,248.1,35.7,252.4,34.5z"></path>
					<path className="st43 IWcSNwFC_17" d="M433.7,69.3l11.4,19.1c2.1,3.6,6.8,4.8,10.4,2.8l45-25.2c3.4-1.9,4.8-6,3.4-9.6l-8.8-22.1
			c-1.8-4.4-7-6.2-11.1-3.8l-47.5,28.2C432.7,60.9,431.5,65.7,433.7,69.3z"></path>
					<path className="st43 IWcSNwFC_18" d="M519.3,22.5l8.3,22.8c3.3,9.2,14,13.3,22.7,8.8l18.1-9.4"></path>
					<path className="st43 IWcSNwFC_19" d="M690.7,150.4C690.7,150.4,690.7,150.4,690.7,150.4l44.6,95.9"></path>
					<path className="st43 IWcSNwFC_20" d="M364.6,662.9l3,7.9c1.6,4.1,6.3,6.1,10.4,4.3l10.8-4.8c3.9-1.7,5.7-6.3,4-10.2l-3.4-7.8
			c-1.7-3.9-6.3-5.7-10.3-4l-10.5,4.7C364.9,654.7,363.2,659,364.6,662.9z"></path>
					<path className="st43 IWcSNwFC_21" d="M665,631.7l-29.3-98c-1.4-4.8-6.9-7-11.2-4.5l-21.4,12.1c-3.1,1.8-4.6,5.4-3.6,8.8l32.2,113.4"></path>
					<path className="st43 IWcSNwFC_22" d="M760.9,434.7L760.9,434.7L653.7,478c-4,1.6-8.5-0.4-10.1-4.4l-39.4-101.8c-1.4-3.7,0.2-8,3.8-9.7l123.8-61
			l3-1.5c3.8-1.9,8.3-0.4,10.3,3.3l18.6,35.5v0"></path>
					<path className="st43 IWcSNwFC_23" d="M578.8,292.2l13.6,34.8c1.6,4.1-0.5,8.7-4.7,10.1l-46.4,16c-3.8,1.3-8-0.5-9.6-4.2l-15.4-34.6
			c-1.9-4.2,0.3-9,4.6-10.5l48.2-16.3C573,286.4,577.3,288.4,578.8,292.2z"></path>
				</g>
				<g>
					<g>
						<g>
							<path className="st45 IWcSNwFC_41" d="M59.1,310.4v10h-1v-8l-3.9,6.8h-0.5l-3.9-6.7v8h-1v-10h0.9l4.4,7.4l4.3-7.4H59.1z"></path>
							<path className="st45 IWcSNwFC_42" d="M63.3,320.1c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2s0.8-1,1.4-1.4
					c0.6-0.3,1.2-0.5,2-0.5c0.7,0,1.4,0.2,2,0.5c0.6,0.3,1,0.8,1.4,1.4c0.3,0.6,0.5,1.2,0.5,2c0,0.7-0.2,1.4-0.5,2
					c-0.3,0.6-0.8,1-1.4,1.4c-0.6,0.3-1.2,0.5-2,0.5C64.5,320.6,63.9,320.4,63.3,320.1z M66.7,319.3c0.4-0.2,0.8-0.6,1-1
					c0.2-0.4,0.4-1,0.4-1.5c0-0.6-0.1-1.1-0.4-1.5c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4
					c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,1-0.4,1.5c0,0.6,0.1,1.1,0.4,1.5s0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
					C65.8,319.6,66.3,319.5,66.7,319.3z"></path>
							<path className="st45 IWcSNwFC_43" d="M77.2,313.7c0.6,0.5,0.8,1.4,0.8,2.4v4.4h-1v-4.3c0-0.8-0.2-1.4-0.6-1.8c-0.4-0.4-1-0.6-1.7-0.6
					c-0.8,0-1.5,0.2-1.9,0.7c-0.5,0.5-0.7,1.1-0.7,2v4h-1v-7.5h1v1.4c0.3-0.5,0.7-0.8,1.2-1.1s1.1-0.4,1.7-0.4
					C75.9,312.9,76.7,313.2,77.2,313.7z"></path>
							<path className="st45 IWcSNwFC_44" d="M87.4,317h-6.3c0.1,0.8,0.4,1.4,0.9,1.9s1.2,0.7,2.1,0.7c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.4,1-0.7
					l0.6,0.7c-0.3,0.4-0.8,0.7-1.3,0.9s-1.1,0.3-1.7,0.3c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2
					c0-0.7,0.2-1.4,0.5-2s0.8-1,1.3-1.4c0.6-0.3,1.2-0.5,1.9-0.5c0.7,0,1.3,0.2,1.9,0.5c0.6,0.3,1,0.8,1.3,1.4
					c0.3,0.6,0.5,1.2,0.5,2L87.4,317z M82,314.4c-0.5,0.5-0.8,1.1-0.8,1.8h5.3c-0.1-0.7-0.3-1.4-0.8-1.8c-0.5-0.5-1.1-0.7-1.8-0.7
					C83.1,313.8,82.5,314,82,314.4z"></path>
							<path className="st45 IWcSNwFC_45" d="M95.7,312.9l-3.8,8.4c-0.3,0.7-0.7,1.2-1.1,1.5c-0.4,0.3-0.9,0.4-1.4,0.4c-0.4,0-0.7-0.1-1-0.2
					c-0.3-0.1-0.6-0.3-0.8-0.5l0.5-0.8c0.4,0.4,0.8,0.6,1.4,0.6c0.3,0,0.6-0.1,0.9-0.3s0.5-0.5,0.7-1l0.3-0.7l-3.4-7.5h1.1l2.8,6.4
					l2.8-6.4H95.7z"></path>
							<path className="st45 IWcSNwFC_46" d="M108.2,315.5h1v3.8c-0.5,0.4-1,0.7-1.7,0.9s-1.3,0.3-2,0.3c-1,0-1.9-0.2-2.7-0.7c-0.8-0.4-1.4-1-1.9-1.8
					c-0.5-0.8-0.7-1.7-0.7-2.6s0.2-1.8,0.7-2.6c0.5-0.8,1.1-1.4,1.9-1.8c0.8-0.4,1.7-0.7,2.7-0.7c0.8,0,1.5,0.1,2.1,0.4
					s1.2,0.6,1.6,1.1l-0.7,0.7c-0.8-0.8-1.8-1.2-3-1.2c-0.8,0-1.5,0.2-2.2,0.5s-1.2,0.9-1.5,1.5s-0.6,1.3-0.6,2.1s0.2,1.5,0.6,2.1
					s0.9,1.1,1.5,1.5s1.4,0.5,2.2,0.5c1,0,1.9-0.3,2.6-0.8V315.5z"></path>
							<path className="st45 IWcSNwFC_47" d="M114.1,313.3c0.5-0.3,1.1-0.4,1.8-0.4v1l-0.2,0c-0.8,0-1.4,0.2-1.9,0.7c-0.4,0.5-0.7,1.2-0.7,2.1v3.8h-1
					v-7.5h1v1.5C113.2,313.9,113.6,313.5,114.1,313.3z"></path>
							<path className="st45 IWcSNwFC_48" d="M122.5,313.6c0.5,0.5,0.8,1.2,0.8,2.2v4.7h-1v-1.2c-0.2,0.4-0.6,0.7-1,0.9c-0.4,0.2-1,0.3-1.6,0.3
					c-0.8,0-1.5-0.2-2-0.6c-0.5-0.4-0.7-0.9-0.7-1.6c0-0.6,0.2-1.2,0.7-1.5c0.5-0.4,1.2-0.6,2.2-0.6h2.4v-0.5c0-0.6-0.2-1.1-0.5-1.5
					s-0.9-0.5-1.6-0.5c-0.5,0-0.9,0.1-1.4,0.2s-0.8,0.4-1.1,0.7l-0.5-0.8c0.4-0.3,0.8-0.6,1.4-0.8c0.5-0.2,1.1-0.3,1.7-0.3
					C121.3,312.9,122,313.1,122.5,313.6z M121.4,319.4c0.4-0.3,0.7-0.6,0.9-1.1V317h-2.4c-1.3,0-1.9,0.4-1.9,1.3
					c0,0.4,0.2,0.8,0.5,1c0.3,0.3,0.8,0.4,1.4,0.4C120.5,319.7,121,319.6,121.4,319.4z"></path>
							<path className="st45 IWcSNwFC_49" d="M137.7,313.7c0.5,0.5,0.8,1.3,0.8,2.4v4.4h-1v-4.3c0-0.8-0.2-1.4-0.6-1.8c-0.4-0.4-0.9-0.6-1.6-0.6
					c-0.8,0-1.4,0.2-1.8,0.7s-0.7,1.1-0.7,2v4h-1v-4.3c0-0.8-0.2-1.4-0.6-1.8c-0.4-0.4-0.9-0.6-1.6-0.6c-0.8,0-1.4,0.2-1.8,0.7
					c-0.5,0.5-0.7,1.1-0.7,2v4h-1v-7.5h1v1.4c0.3-0.5,0.6-0.8,1.1-1.1c0.5-0.2,1-0.4,1.6-0.4c0.6,0,1.2,0.1,1.6,0.4
					c0.5,0.3,0.8,0.7,1,1.2c0.3-0.5,0.7-0.9,1.2-1.2c0.5-0.3,1.1-0.4,1.8-0.4C136.4,312.9,137.2,313.2,137.7,313.7z"></path>
						</g>
					</g>
					<g>
						<path className="st45 IWcSNwFC_50" d="M134.2,254.5c-0.7,0-1.3-0.2-1.8-0.5l0.2-0.9c0.4,0.3,1,0.4,1.6,0.5c0.6,0.1,1.3,0,1.9-0.2
				c0.9-0.2,1.5-0.6,1.8-1c0.3-0.4,0.4-0.9,0.3-1.4c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3c-0.4,0-0.9,0-1.5,0
				c-0.8,0-1.4,0-1.9,0c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.7-0.7-0.9-1.3c-0.1-0.5-0.1-1,0-1.4c0.1-0.5,0.5-0.9,0.9-1.3
				c0.5-0.4,1.1-0.7,1.9-0.9c0.6-0.2,1.1-0.2,1.7-0.2c0.6,0,1.1,0.1,1.5,0.2l-0.1,0.9c-0.5-0.2-1-0.2-1.5-0.2s-1,0.1-1.4,0.2
				c-0.8,0.2-1.4,0.6-1.8,1c-0.3,0.4-0.4,0.9-0.3,1.4c0.1,0.4,0.3,0.7,0.6,0.8s0.6,0.3,1,0.3c0.4,0,0.9,0,1.5,0c0.8,0,1.4,0,1.9,0
				c0.5,0,0.9,0.2,1.3,0.5s0.7,0.7,0.8,1.3c0.1,0.5,0.1,1,0,1.4c-0.2,0.5-0.5,0.9-0.9,1.3c-0.5,0.4-1.1,0.7-1.9,0.9
				C135.6,254.4,134.9,254.5,134.2,254.5z"></path>
						<path className="st45 IWcSNwFC_51" d="M139.4,243.5c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.2,0-0.4,0.1-0.5s0.3-0.3,0.5-0.3s0.4,0,0.6,0.1
				c0.2,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.3S139.6,243.6,139.4,243.5z M139.9,245.3l1-0.3l2,7.3
				l-1,0.3L139.9,245.3z"></path>
						<path className="st45 IWcSNwFC_52" d="M155,241.9c0.7,0.4,1.1,1.1,1.4,2.1l1.2,4.2l-1,0.3l-1.1-4.1c-0.2-0.8-0.6-1.3-1-1.6
				c-0.5-0.3-1-0.4-1.7-0.2c-0.8,0.2-1.3,0.6-1.6,1.2c-0.3,0.6-0.3,1.3-0.1,2.1l1.1,3.8l-1,0.3l-1.1-4.1c-0.2-0.8-0.6-1.3-1-1.6
				c-0.5-0.3-1-0.3-1.7-0.2c-0.7,0.2-1.3,0.6-1.6,1.2c-0.3,0.6-0.3,1.3-0.1,2.1l1.1,3.8l-1,0.3l-2-7.3l0.9-0.3l0.4,1.3
				c0.1-0.5,0.4-1,0.8-1.3c0.4-0.4,0.9-0.6,1.5-0.8c0.6-0.2,1.2-0.2,1.7-0.1c0.5,0.1,1,0.4,1.3,0.9c0.1-0.6,0.4-1,0.8-1.4
				c0.4-0.4,1-0.7,1.6-0.9C153.6,241.5,154.4,241.5,155,241.9z"></path>
						<path className="st45 IWcSNwFC_53" d="M161.3,246.8c-0.7-0.2-1.2-0.5-1.7-1c-0.5-0.5-0.8-1.1-1-1.8c-0.2-0.7-0.2-1.4,0-2s0.5-1.2,1-1.7
				c0.5-0.5,1.1-0.8,1.8-1c0.7-0.2,1.4-0.2,2-0.1c0.6,0.2,1.2,0.5,1.7,0.9c0.5,0.5,0.8,1.1,1,1.8c0.2,0.7,0.2,1.4,0.1,2
				s-0.5,1.2-1,1.7c-0.5,0.5-1.1,0.8-1.8,1C162.6,246.9,161.9,246.9,161.3,246.8z M164.3,245.1c0.3-0.3,0.6-0.8,0.7-1.3
				c0.1-0.5,0.1-1-0.1-1.6c-0.2-0.6-0.4-1-0.8-1.4c-0.4-0.4-0.8-0.6-1.2-0.7c-0.5-0.1-1-0.1-1.5,0c-0.5,0.1-0.9,0.4-1.3,0.7
				c-0.3,0.3-0.6,0.8-0.7,1.3c-0.1,0.5-0.1,1,0.1,1.6c0.2,0.6,0.4,1,0.8,1.4s0.8,0.6,1.2,0.7c0.5,0.1,1,0.1,1.5,0
				C163.6,245.7,164,245.5,164.3,245.1z"></path>
						<path className="st45 IWcSNwFC_54" d="M173,236.9c0.7,0.4,1.2,1.1,1.5,2.1l1.2,4.2l-1,0.3l-1.1-4.1c-0.2-0.8-0.6-1.3-1-1.6
				c-0.5-0.3-1.1-0.3-1.8-0.1c-0.8,0.2-1.3,0.6-1.7,1.2c-0.3,0.6-0.4,1.3-0.1,2.1l1.1,3.8l-1,0.3l-2-7.3l0.9-0.3l0.4,1.3
				c0.1-0.5,0.4-1,0.8-1.3c0.4-0.4,0.9-0.7,1.5-0.8C171.6,236.5,172.4,236.5,173,236.9z"></path>
						<path className="st45 IWcSNwFC_55" d="M187.5,229.5l-1.6,10.9l-1,0.3l-7-8.5l1.1-0.3l6,7.4l1.4-9.4L187.5,229.5z"></path>
						<path className="st45 IWcSNwFC_56" d="M191.1,231.4c0.4-0.4,0.9-0.7,1.6-0.8l0.3,1l-0.2,0.1c-0.8,0.2-1.3,0.6-1.6,1.2c-0.3,0.6-0.3,1.3-0.1,2.2
				l1,3.7l-1,0.3l-2-7.3l0.9-0.3l0.4,1.4C190.5,232.3,190.8,231.8,191.1,231.4z"></path>
						<path className="st45 IWcSNwFC_57" d="M199.4,229.5c0.6,0.3,1.1,1,1.3,1.9l1.3,4.5l-0.9,0.3l-0.3-1.1c-0.1,0.4-0.4,0.8-0.7,1.2
				c-0.4,0.3-0.8,0.6-1.4,0.7c-0.8,0.2-1.5,0.2-2.1,0c-0.6-0.3-1-0.7-1.1-1.3c-0.2-0.6-0.1-1.2,0.3-1.7c0.3-0.5,1-0.9,2-1.2l2.3-0.6
				l-0.1-0.4c-0.2-0.6-0.5-1.1-0.9-1.3c-0.4-0.2-1-0.3-1.7-0.1c-0.5,0.1-0.9,0.3-1.3,0.6c-0.4,0.3-0.7,0.6-0.9,0.9l-0.6-0.6
				c0.3-0.4,0.7-0.8,1.1-1.1c0.5-0.3,1-0.5,1.6-0.7C198,229.1,198.8,229.2,199.4,229.5z M199.8,235.3c0.3-0.4,0.5-0.8,0.6-1.3
				l-0.3-1.2l-2.3,0.6c-1.2,0.3-1.7,1-1.5,1.8c0.1,0.4,0.4,0.7,0.8,0.9c0.4,0.2,0.9,0.2,1.5,0C199.1,235.9,199.5,235.7,199.8,235.3z
				"></path>
						<path className="st45 IWcSNwFC_58" d="M208.4,233.6c-0.1,0.2-0.3,0.4-0.6,0.6s-0.5,0.3-0.8,0.4c-0.7,0.2-1.3,0.2-1.7-0.1c-0.5-0.3-0.8-0.7-1-1.4
				l-1.2-4.4l-1.3,0.4l-0.2-0.8l1.3-0.4l-0.4-1.6l1-0.3l0.4,1.6l2.2-0.6l0.2,0.8l-2.2,0.6l1.2,4.3c0.1,0.4,0.3,0.7,0.6,0.9
				c0.3,0.2,0.6,0.2,1,0.1c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.4L208.4,233.6z"></path>
						<path className="st45 IWcSNwFC_59" d="M210.5,233.3c-0.6,0-1.1-0.1-1.4-0.3l0.2-0.9c0.4,0.2,0.8,0.2,1.3,0.3c0.5,0,1,0,1.5-0.2
				c0.7-0.2,1.1-0.4,1.4-0.7c0.3-0.3,0.3-0.6,0.2-1c-0.1-0.3-0.2-0.5-0.4-0.6c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.7,0-1.1,0.1
				c-0.6,0.1-1.1,0.1-1.5,0.1c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.6-0.7-1c-0.2-0.6-0.1-1.2,0.3-1.7c0.4-0.5,1-0.9,2-1.2
				c0.5-0.1,1-0.2,1.5-0.2c0.5,0,0.9,0,1.3,0.2l-0.2,0.9c-0.7-0.2-1.5-0.3-2.3,0c-0.6,0.2-1.1,0.4-1.3,0.7c-0.3,0.3-0.3,0.6-0.2,1
				c0.1,0.3,0.2,0.5,0.5,0.6c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.7,0,1.2-0.1c0.6-0.1,1.1-0.1,1.5-0.1c0.4,0,0.7,0.1,1.1,0.3
				c0.3,0.2,0.6,0.5,0.7,1c0.2,0.6,0.1,1.2-0.4,1.7c-0.4,0.5-1.1,0.9-2,1.2C211.7,233.3,211.1,233.3,210.5,233.3z"></path>
						<path className="st45 IWcSNwFC_60" d="M221,222.8l-1.4,9.1c-0.1,0.8-0.3,1.3-0.6,1.7c-0.3,0.4-0.7,0.7-1.3,0.8c-0.4,0.1-0.7,0.1-1,0.1
				c-0.3,0-0.6-0.1-0.9-0.3l0.3-0.9c0.5,0.3,1,0.3,1.5,0.2c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.3-0.6,0.4-1.1l0.1-0.8l-5.3-6.4l1-0.3
				l4.5,5.4l1-6.9L221,222.8z"></path>
						<path className="st45 IWcSNwFC_61" d="M227,221.8c0.6,0.3,1.1,1,1.3,1.9l1.3,4.5l-0.9,0.3l-0.3-1.1c-0.1,0.4-0.4,0.8-0.7,1.2
				c-0.4,0.3-0.8,0.6-1.4,0.7c-0.8,0.2-1.5,0.2-2.1,0c-0.6-0.3-1-0.7-1.1-1.3c-0.2-0.6-0.1-1.2,0.3-1.7c0.3-0.5,1-0.9,2-1.2l2.3-0.6
				l-0.1-0.4c-0.2-0.6-0.5-1.1-0.9-1.3c-0.4-0.2-1-0.3-1.7-0.1c-0.5,0.1-0.9,0.3-1.3,0.6c-0.4,0.3-0.7,0.6-0.9,0.9l-0.6-0.6
				c0.3-0.4,0.7-0.8,1.1-1.1c0.5-0.3,1-0.5,1.6-0.7C225.6,221.4,226.4,221.5,227,221.8z M227.5,227.6c0.3-0.4,0.5-0.8,0.6-1.3
				l-0.3-1.2l-2.3,0.6c-1.2,0.3-1.7,1-1.5,1.8c0.1,0.4,0.4,0.7,0.8,0.9s0.9,0.2,1.5,0C226.7,228.2,227.2,228,227.5,227.6z"></path>
						<path className="st45 IWcSNwFC_62" d="M236.4,219.3c0.7,0.4,1.2,1.1,1.5,2.1l1.2,4.2l-1,0.3l-1.1-4.1c-0.2-0.8-0.6-1.3-1-1.6
				c-0.5-0.3-1.1-0.3-1.8-0.1c-0.8,0.2-1.3,0.6-1.7,1.2c-0.3,0.6-0.4,1.3-0.1,2.1l1.1,3.8l-1,0.3l-2-7.3l0.9-0.3l0.4,1.3
				c0.1-0.5,0.4-1,0.8-1.3c0.4-0.4,0.9-0.7,1.5-0.8C234.9,218.8,235.7,218.9,236.4,219.3z"></path>
						<path className="st45 IWcSNwFC_63" d="M246,223.4c-0.7,0-1.3-0.2-1.8-0.5l0.2-0.9c0.4,0.3,1,0.4,1.6,0.5c0.6,0.1,1.3,0,1.9-0.2
				c0.9-0.2,1.5-0.6,1.8-1c0.3-0.4,0.4-0.9,0.3-1.4c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3c-0.4,0-0.9,0-1.5,0
				c-0.8,0-1.4,0-1.9,0c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.7-0.7-0.9-1.3c-0.1-0.5-0.1-1,0-1.4c0.1-0.5,0.5-0.9,0.9-1.3
				c0.5-0.4,1.1-0.7,1.9-0.9c0.6-0.2,1.1-0.2,1.7-0.2c0.6,0,1.1,0.1,1.5,0.2l-0.1,0.9c-0.5-0.2-1-0.2-1.5-0.2c-0.5,0-1,0.1-1.4,0.2
				c-0.8,0.2-1.4,0.6-1.8,1s-0.4,0.9-0.3,1.4c0.1,0.4,0.3,0.7,0.6,0.8s0.6,0.3,1,0.3c0.4,0,0.9,0,1.5,0c0.8,0,1.4,0,1.9,0
				c0.5,0,0.9,0.2,1.3,0.5c0.4,0.3,0.7,0.7,0.8,1.3c0.1,0.5,0.1,1,0,1.4c-0.2,0.5-0.5,0.9-0.9,1.3c-0.5,0.4-1.1,0.7-1.9,0.9
				C247.5,223.3,246.7,223.4,246,223.4z"></path>
						<path className="st45 IWcSNwFC_64" d="M257.5,220c-0.1,0.2-0.3,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.8,0.4c-0.7,0.2-1.3,0.2-1.7-0.1s-0.8-0.7-1-1.4
				l-1.2-4.4l-1.3,0.4l-0.2-0.8l1.3-0.4l-0.4-1.6l1-0.3l0.4,1.6l2.2-0.6l0.2,0.8l-2.2,0.6l1.2,4.3c0.1,0.4,0.3,0.7,0.6,0.9
				c0.3,0.2,0.6,0.2,1,0.1c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.4L257.5,220z"></path>
						<path className="st45 IWcSNwFC_65" d="M259.1,219.8c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.4
				c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
				C259.4,220,259.2,220,259.1,219.8z"></path>
					</g>
					<g>
						<path className="st45 IWcSNwFC_66" d="M331.8,182.8c-0.7,0.1-1.3,0-1.8-0.1l0-0.9c0.5,0.2,1.1,0.2,1.7,0.1c0.6-0.1,1.2-0.2,1.8-0.5
				c0.8-0.4,1.3-0.9,1.6-1.3c0.3-0.5,0.3-1,0-1.4c-0.2-0.4-0.4-0.6-0.8-0.7c-0.3-0.1-0.7-0.1-1-0.1s-0.9,0.1-1.5,0.3
				c-0.8,0.2-1.4,0.3-1.9,0.3c-0.5,0-0.9,0-1.4-0.2c-0.4-0.2-0.8-0.6-1.1-1.1c-0.2-0.5-0.3-0.9-0.3-1.4c0.1-0.5,0.3-1,0.7-1.4
				c0.4-0.5,0.9-0.9,1.7-1.3c0.5-0.3,1.1-0.4,1.6-0.5c0.6-0.1,1.1-0.1,1.6-0.1l0.1,0.9c-0.5-0.1-1,0-1.5,0c-0.5,0.1-0.9,0.2-1.4,0.4
				c-0.8,0.4-1.3,0.8-1.5,1.3c-0.2,0.5-0.2,1,0,1.5c0.2,0.4,0.4,0.6,0.8,0.7c0.3,0.1,0.7,0.1,1.1,0.1c0.4,0,0.9-0.1,1.5-0.3
				c0.7-0.2,1.4-0.3,1.8-0.3c0.5,0,0.9,0,1.4,0.2c0.4,0.2,0.8,0.5,1.1,1.1c0.2,0.5,0.3,0.9,0.3,1.4c-0.1,0.5-0.3,1-0.7,1.4
				c-0.4,0.5-1,0.9-1.7,1.3C333.2,182.5,332.5,182.7,331.8,182.8z"></path>
						<path className="st45 IWcSNwFC_67" d="M334.8,171c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.5c0.1-0.2,0.2-0.3,0.4-0.4s0.4-0.1,0.6-0.1
				c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.2,0.3-0.4,0.4C335.2,171.1,335,171.1,334.8,171z M335.7,172.7
				l0.9-0.5l3.4,6.7l-0.9,0.5L335.7,172.7z"></path>
						<path className="st45 IWcSNwFC_68" d="M349.9,166.4c0.7,0.2,1.3,0.8,1.8,1.8l2,3.9l-0.9,0.5l-1.9-3.8c-0.3-0.7-0.8-1.2-1.3-1.4
				c-0.5-0.2-1.1-0.1-1.7,0.2c-0.7,0.4-1.1,0.8-1.3,1.5c-0.2,0.6-0.1,1.3,0.3,2.1l1.8,3.5l-0.9,0.5l-1.9-3.8
				c-0.4-0.7-0.8-1.2-1.3-1.4c-0.5-0.2-1.1-0.1-1.7,0.2c-0.7,0.3-1.1,0.8-1.3,1.5c-0.2,0.6-0.1,1.3,0.3,2.1l1.8,3.5l-0.9,0.5
				l-3.4-6.7l0.9-0.4l0.6,1.2c0-0.5,0.2-1,0.5-1.4s0.8-0.8,1.3-1.1c0.6-0.3,1.1-0.4,1.6-0.4c0.5,0,1,0.2,1.5,0.6
				c0-0.6,0.2-1.1,0.5-1.6c0.3-0.5,0.8-0.9,1.4-1.2C348.4,166.3,349.2,166.2,349.9,166.4z"></path>
						<path className="st45 IWcSNwFC_69" d="M357,170c-0.7,0-1.3-0.2-1.8-0.6c-0.6-0.4-1-0.9-1.3-1.5c-0.3-0.7-0.5-1.3-0.4-2c0-0.7,0.2-1.3,0.6-1.8
				c0.4-0.6,0.9-1,1.5-1.3s1.3-0.5,2-0.4c0.7,0,1.3,0.2,1.8,0.6c0.6,0.4,1,0.9,1.3,1.6c0.3,0.7,0.5,1.3,0.4,2c0,0.7-0.2,1.3-0.6,1.8
				c-0.4,0.6-0.9,1-1.5,1.3S357.6,170.1,357,170z M359.7,167.8c0.3-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.5c-0.3-0.5-0.6-0.9-1-1.2
				c-0.4-0.3-0.9-0.5-1.4-0.5c-0.5,0-1,0.1-1.5,0.3s-0.9,0.6-1.1,1c-0.3,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.5
				c0.3,0.5,0.6,0.9,1,1.2c0.4,0.3,0.9,0.5,1.4,0.5c0.5,0,1-0.1,1.5-0.3S359.4,168.2,359.7,167.8z"></path>
						<path className="st45 IWcSNwFC_70" d="M366.6,158.1c0.7,0.2,1.4,0.8,1.8,1.8l2,3.9l-0.9,0.5l-1.9-3.8c-0.4-0.7-0.8-1.1-1.3-1.3
				c-0.5-0.2-1.1-0.1-1.8,0.2c-0.7,0.4-1.2,0.9-1.4,1.5c-0.2,0.6-0.1,1.3,0.3,2.1l1.8,3.5l-0.9,0.5l-3.4-6.7l0.9-0.4l0.6,1.2
				c0-0.5,0.2-1,0.6-1.5c0.3-0.4,0.8-0.8,1.4-1.1C365.1,158,365.9,157.9,366.6,158.1z"></path>
						<path className="st45 IWcSNwFC_71" d="M379.4,148.1l0.5,11l-0.9,0.5l-8.5-7l1-0.5l7.3,6.1l-0.4-9.5L379.4,148.1z"></path>
						<path className="st45 IWcSNwFC_72" d="M383.4,149.3c0.3-0.4,0.8-0.8,1.4-1.1l0.4,0.9l-0.2,0.1c-0.7,0.4-1.2,0.8-1.3,1.5c-0.2,0.6-0.1,1.3,0.3,2.1
				l1.7,3.4l-0.9,0.5l-3.4-6.7l0.9-0.4l0.7,1.3C382.9,150.2,383,149.7,383.4,149.3z"></path>
						<path className="st45 IWcSNwFC_73" d="M391.1,145.8c0.7,0.2,1.3,0.7,1.7,1.6l2.1,4.2L394,152l-0.5-1.1c0,0.5-0.2,0.9-0.5,1.3
				c-0.3,0.4-0.7,0.7-1.3,1c-0.8,0.4-1.4,0.5-2.1,0.4c-0.6-0.1-1.1-0.5-1.4-1.1c-0.3-0.6-0.3-1.1-0.1-1.7c0.2-0.6,0.8-1.1,1.7-1.5
				l2.1-1.1l-0.2-0.4c-0.3-0.6-0.7-0.9-1.2-1.1c-0.5-0.1-1-0.1-1.7,0.3c-0.4,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.1l-0.8-0.5
				c0.2-0.5,0.5-0.9,0.9-1.3c0.4-0.4,0.9-0.7,1.4-1C389.6,145.7,390.4,145.6,391.1,145.8z M392.6,151.4c0.2-0.4,0.4-0.9,0.3-1.4
				l-0.6-1.1l-2.1,1.1c-1.2,0.6-1.5,1.3-1.1,2.1c0.2,0.4,0.5,0.6,0.9,0.7c0.4,0.1,0.9,0,1.4-0.3C392,152.2,392.4,151.9,392.6,151.4z
				"></path>
						<path className="st45 IWcSNwFC_74" d="M400.7,148.2c-0.1,0.2-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.7,0.5c-0.6,0.3-1.2,0.4-1.7,0.2
				c-0.5-0.2-0.9-0.6-1.2-1.2l-2-4.1l-1.2,0.6l-0.4-0.8l1.2-0.6l-0.7-1.5l0.9-0.5l0.7,1.5l2.1-1l0.4,0.8l-2.1,1l2,4
				c0.2,0.4,0.5,0.7,0.8,0.8c0.3,0.1,0.6,0.1,1-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.1,0.3-0.3,0.3-0.5L400.7,148.2z"></path>
						<path className="st45 IWcSNwFC_75" d="M402.7,147.5c-0.6,0.1-1.1,0.1-1.5,0l0.1-0.9c0.4,0.1,0.8,0.1,1.3,0c0.5-0.1,1-0.2,1.4-0.5
				c0.6-0.3,1-0.6,1.2-1c0.2-0.3,0.2-0.7,0-1c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.1-0.8,0s-0.6,0.1-1.1,0.3
				c-0.6,0.2-1.1,0.3-1.5,0.3c-0.4,0.1-0.8,0-1.1-0.1c-0.4-0.1-0.7-0.4-0.9-0.9c-0.3-0.6-0.3-1.1,0-1.7c0.3-0.6,0.9-1.1,1.7-1.5
				c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,0.9-0.1,1.3-0.1l0,0.9c-0.7-0.1-1.5,0-2.3,0.4c-0.6,0.3-1,0.6-1.2,1c-0.2,0.4-0.2,0.7,0,1
				c0.1,0.3,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.1,0.8,0c0.3-0.1,0.7-0.1,1.1-0.3c0.6-0.2,1.1-0.3,1.5-0.3c0.4-0.1,0.7,0,1.1,0.1
				s0.6,0.4,0.9,0.9c0.3,0.6,0.3,1.2,0,1.8c-0.3,0.6-0.9,1.1-1.8,1.5C403.8,147.2,403.3,147.4,402.7,147.5z"></path>
						<path className="st45 IWcSNwFC_76" d="M411,135.1l0.4,9.2c0,0.8,0,1.4-0.3,1.8c-0.2,0.4-0.6,0.8-1.1,1c-0.3,0.2-0.7,0.3-1,0.3c-0.3,0-0.7,0-1-0.1
				l0.1-0.9c0.5,0.2,1,0.1,1.5-0.1c0.3-0.2,0.5-0.4,0.7-0.7c0.1-0.3,0.2-0.7,0.2-1.2l0-0.8l-6.4-5.2l0.9-0.5l5.4,4.5l-0.3-7
				L411,135.1z"></path>
						<path className="st45 IWcSNwFC_77" d="M416.8,133c0.7,0.2,1.3,0.7,1.7,1.6l2.1,4.2l-0.9,0.4l-0.5-1.1c0,0.5-0.2,0.9-0.5,1.3
				c-0.3,0.4-0.7,0.7-1.3,1c-0.8,0.4-1.4,0.5-2.1,0.4c-0.6-0.1-1.1-0.5-1.4-1.1c-0.3-0.6-0.3-1.1-0.1-1.7s0.8-1.1,1.7-1.5l2.1-1.1
				l-0.2-0.4c-0.3-0.6-0.7-0.9-1.2-1.1c-0.5-0.1-1-0.1-1.7,0.3c-0.4,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.1l-0.8-0.5
				c0.2-0.5,0.5-0.9,0.9-1.3c0.4-0.4,0.9-0.7,1.4-1C415.3,132.9,416.1,132.8,416.8,133z M418.3,138.6c0.2-0.4,0.4-0.9,0.3-1.4
				l-0.6-1.1l-2.1,1.1c-1.2,0.6-1.5,1.3-1.1,2.1c0.2,0.4,0.5,0.6,0.9,0.7c0.4,0.1,0.9,0,1.4-0.3C417.7,139.4,418.1,139,418.3,138.6z
				"></path>
						<path className="st45 IWcSNwFC_78" d="M425.4,128.7c0.7,0.2,1.4,0.8,1.8,1.8l2,3.9l-0.9,0.5l-1.9-3.8c-0.3-0.7-0.8-1.1-1.3-1.3
				c-0.5-0.2-1.1-0.1-1.8,0.2c-0.7,0.4-1.2,0.9-1.4,1.5c-0.2,0.6-0.1,1.3,0.3,2.1l1.8,3.5l-0.9,0.5l-3.4-6.7l0.9-0.4l0.6,1.2
				c0-0.5,0.2-1,0.6-1.5s0.8-0.8,1.4-1.1C423.9,128.6,424.7,128.5,425.4,128.7z"></path>
						<path className="st45 IWcSNwFC_79" d="M435.7,130.9c-0.7,0.1-1.3,0-1.8-0.1l0-0.9c0.5,0.2,1.1,0.2,1.7,0.1c0.6-0.1,1.2-0.2,1.8-0.5
				c0.8-0.4,1.3-0.9,1.6-1.3c0.3-0.5,0.3-1,0-1.4c-0.2-0.4-0.4-0.6-0.8-0.7c-0.3-0.1-0.7-0.1-1-0.1c-0.4,0-0.9,0.1-1.5,0.3
				c-0.8,0.2-1.4,0.3-1.9,0.3c-0.5,0-0.9,0-1.4-0.2c-0.4-0.2-0.8-0.6-1.1-1.1c-0.2-0.5-0.3-0.9-0.3-1.4c0.1-0.5,0.3-1,0.7-1.4
				c0.4-0.5,0.9-0.9,1.7-1.3c0.5-0.3,1.1-0.4,1.6-0.5c0.6-0.1,1.1-0.1,1.6-0.1l0.1,0.9c-0.5-0.1-1,0-1.5,0c-0.5,0.1-0.9,0.2-1.4,0.4
				c-0.8,0.4-1.3,0.8-1.5,1.3c-0.2,0.5-0.2,1,0,1.5c0.2,0.4,0.4,0.6,0.8,0.7c0.3,0.1,0.7,0.1,1.1,0.1c0.4,0,0.9-0.1,1.5-0.3
				c0.7-0.2,1.4-0.3,1.8-0.3c0.5,0,0.9,0,1.4,0.2c0.4,0.2,0.8,0.5,1.1,1.1c0.2,0.5,0.3,0.9,0.3,1.4c-0.1,0.5-0.3,1-0.7,1.4
				c-0.4,0.5-1,0.9-1.7,1.3C437.1,130.6,436.4,130.8,435.7,130.9z"></path>
						<path className="st45 IWcSNwFC_80" d="M446.3,125.4c-0.1,0.2-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.7,0.5c-0.6,0.3-1.2,0.4-1.7,0.2
				c-0.5-0.2-0.9-0.6-1.2-1.2l-2-4.1l-1.2,0.6l-0.4-0.8l1.2-0.6l-0.7-1.5l0.9-0.5l0.7,1.5l2.1-1l0.4,0.8l-2.1,1l2,4
				c0.2,0.4,0.5,0.7,0.8,0.8c0.3,0.1,0.6,0.1,1-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.1,0.3-0.3,0.3-0.5L446.3,125.4z"></path>
						<path className="st45 IWcSNwFC_81" d="M447.8,125c-0.2-0.1-0.4-0.2-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.2-0.3,0.4-0.4
				c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0,0.6s-0.2,0.4-0.4,0.4C448.2,125,448,125,447.8,125z"></path>
					</g>
					<g>
						<path className="st45 IWcSNwFC_82" d="M307.5,327.2l-2.6-1.4l-2.5,0.7l-0.3-1l9.6-2.8l0.3,1l-5.8,1.7l7.5,4l0.3,1.2l-5.6-2.9l-4,6l-0.3-1.2
				L307.5,327.2z"></path>
						<path className="st45 IWcSNwFC_83" d="M312.7,337.7c-0.3,0.6-1,1.1-1.9,1.4l-4.5,1.3l-0.3-0.9l1.1-0.3c-0.4-0.1-0.8-0.4-1.2-0.7
				c-0.3-0.4-0.6-0.8-0.8-1.4c-0.2-0.8-0.2-1.5,0-2.1c0.2-0.6,0.7-1,1.3-1.2c0.6-0.2,1.2-0.1,1.7,0.2c0.5,0.3,0.9,1,1.2,2l0.7,2.3
				l0.4-0.1c0.6-0.2,1.1-0.5,1.3-0.9c0.2-0.4,0.2-1,0.1-1.7c-0.1-0.5-0.3-0.9-0.6-1.3s-0.6-0.7-0.9-0.9l0.6-0.7
				c0.4,0.3,0.8,0.7,1.1,1.1c0.3,0.5,0.6,1,0.7,1.6C313.1,336.3,313.1,337.1,312.7,337.7z M306.9,338.2c0.4,0.3,0.8,0.5,1.3,0.6
				l1.2-0.3l-0.7-2.3c-0.4-1.2-1-1.7-1.8-1.5c-0.4,0.1-0.7,0.4-0.9,0.8c-0.2,0.4-0.1,0.9,0,1.5C306.3,337.5,306.5,337.9,306.9,338.2
				z"></path>
						<path className="st45 IWcSNwFC_84" d="M303.9,341.9c0-0.3,0.1-0.5,0.2-0.8l0.8,0.1c-0.2,0.4-0.2,0.8,0,1.2c0.1,0.4,0.3,0.6,0.6,0.8
				c0.3,0.1,0.7,0.1,1.1,0l7.8-2.2l0.3,1l-7.8,2.2c-0.7,0.2-1.3,0.2-1.8-0.1c-0.5-0.3-0.8-0.7-1-1.4
				C304,342.5,303.9,342.2,303.9,341.9z M316.2,340.5c0.1-0.2,0.2-0.3,0.4-0.3c0.2-0.1,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.5
				s0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0.1-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.5C316.1,340.9,316.1,340.7,316.2,340.5z
				"></path>
						<path className="st45 IWcSNwFC_85" d="M316.1,349.6c-0.3,0.6-1,1.1-1.9,1.4l-4.5,1.3l-0.3-0.9l1.1-0.3c-0.4-0.1-0.8-0.4-1.2-0.7
				c-0.3-0.4-0.6-0.8-0.8-1.4c-0.2-0.8-0.2-1.5,0-2.1c0.2-0.6,0.7-1,1.3-1.2c0.6-0.2,1.2-0.1,1.7,0.2c0.5,0.3,0.9,1,1.2,2l0.7,2.3
				l0.4-0.1c0.6-0.2,1-0.5,1.3-0.9s0.2-1,0.1-1.7c-0.1-0.5-0.3-0.9-0.6-1.3s-0.6-0.7-0.9-0.9l0.6-0.7c0.4,0.3,0.8,0.7,1.1,1.1
				c0.3,0.5,0.6,1,0.7,1.6C316.5,348.2,316.5,349,316.1,349.6z M310.3,350.1c0.4,0.3,0.8,0.5,1.3,0.6l1.2-0.3l-0.7-2.3
				c-0.4-1.2-1-1.7-1.8-1.5c-0.4,0.1-0.7,0.4-0.9,0.8c-0.2,0.4-0.1,0.9,0,1.5C309.7,349.4,310,349.8,310.3,350.1z"></path>
						<path className="st45 IWcSNwFC_86" d="M312.8,359.8l-0.8,0.2l-1.7-5.9l0.6-0.2l7.1,2.9l-1.3-4.5l0.8-0.2l1.6,5.7l-0.6,0.2l-7.1-2.9L312.8,359.8z"></path>
						<path className="st45 IWcSNwFC_87" d="M320.7,366c-0.4,0.7-1.1,1.2-2.1,1.5l-4.2,1.2l-0.3-1l4.1-1.2c0.8-0.2,1.3-0.6,1.6-1.1s0.3-1.1,0.1-1.8
				c-0.2-0.8-0.6-1.3-1.2-1.7c-0.6-0.3-1.3-0.4-2.1-0.1l-3.8,1.1l-0.3-1l7.3-2.1l0.3,0.9l-1.3,0.4c0.5,0.1,1,0.4,1.3,0.8
				c0.4,0.4,0.7,0.9,0.8,1.5C321.2,364.5,321.1,365.3,320.7,366z"></path>
						<path className="st45 IWcSNwFC_88" d="M324.3,375.8l-7.3,2.1l-0.3-0.9l1.3-0.4c-0.5-0.1-1-0.4-1.3-0.8c-0.4-0.4-0.6-0.9-0.8-1.4
				c-0.3-0.9-0.2-1.8,0.2-2.5c0.4-0.7,1.1-1.2,2.1-1.5l4.2-1.2l0.3,1l-4.1,1.2c-0.8,0.2-1.3,0.6-1.6,1.1c-0.3,0.5-0.3,1.1-0.1,1.8
				c0.2,0.8,0.6,1.3,1.2,1.6c0.6,0.3,1.3,0.3,2.1,0.1l3.8-1.1L324.3,375.8z"></path>
						<path className="st45 IWcSNwFC_89" d="M326.1,384.6c-0.4,0.7-1.1,1.2-2.1,1.5l-4.2,1.2l-0.3-1l4.1-1.2c0.8-0.2,1.3-0.6,1.6-1.1
				c0.3-0.5,0.3-1.1,0.1-1.8c-0.2-0.8-0.6-1.3-1.2-1.7c-0.6-0.3-1.3-0.4-2.1-0.1l-3.8,1.1l-0.3-1l7.3-2.1l0.3,0.9l-1.3,0.4
				c0.5,0.1,1,0.4,1.3,0.8c0.4,0.4,0.7,0.9,0.8,1.5C326.5,383.1,326.4,383.9,326.1,384.6z"></path>
						<path className="st45 IWcSNwFC_90" d="M327.8,387.9l0.3,1l-7.3,2.1l-0.3-1L327.8,387.9z M329.6,387.3c0.1-0.2,0.2-0.3,0.4-0.3
				c0.2-0.1,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.5s0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0.1-0.4,0-0.6-0.1
				c-0.2-0.1-0.3-0.2-0.4-0.5C329.5,387.7,329.5,387.5,329.6,387.3z"></path>
						<path className="st45 IWcSNwFC_91" d="M323.1,398c0-0.7,0.2-1.3,0.5-1.8l0.9,0.2c-0.2,0.4-0.4,1-0.4,1.6c0,0.6,0,1.3,0.2,1.9
				c0.2,0.9,0.6,1.5,1,1.8s0.9,0.4,1.4,0.3c0.4-0.1,0.7-0.3,0.8-0.6c0.2-0.3,0.3-0.6,0.3-1c0-0.4,0-0.9,0-1.5c0-0.8,0-1.4,0-1.9
				c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.7-0.7,1.3-0.9c0.5-0.1,1-0.1,1.4,0c0.5,0.1,0.9,0.4,1.3,0.9c0.4,0.5,0.7,1.1,0.9,1.9
				c0.2,0.6,0.2,1.1,0.2,1.7c0,0.6-0.1,1.1-0.2,1.6l-0.9-0.1c0.2-0.5,0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.4c-0.2-0.8-0.6-1.4-1-1.8
				c-0.4-0.3-0.9-0.4-1.4-0.3c-0.4,0.1-0.7,0.3-0.8,0.6s-0.3,0.6-0.3,1c0,0.4,0,0.9,0,1.5c0,0.8,0,1.4,0,1.9c0,0.5-0.2,0.9-0.5,1.3
				c-0.3,0.4-0.7,0.7-1.3,0.9c-0.5,0.1-1,0.1-1.4,0c-0.5-0.1-0.9-0.5-1.3-0.9c-0.4-0.5-0.7-1.1-0.9-1.9
				C323.2,399.4,323.1,398.7,323.1,398z"></path>
						<path className="st45 IWcSNwFC_92" d="M326.6,409.4c-0.2-0.1-0.4-0.3-0.6-0.6c-0.2-0.2-0.3-0.5-0.4-0.8c-0.2-0.7-0.2-1.3,0.1-1.7
				c0.3-0.5,0.7-0.8,1.4-1l4.4-1.3l-0.4-1.3l0.8-0.2l0.4,1.3l1.6-0.5l0.3,1l-1.6,0.5l0.6,2.2l-0.8,0.2l-0.6-2.2l-4.3,1.2
				c-0.4,0.1-0.7,0.3-0.9,0.6c-0.2,0.3-0.2,0.6-0.1,1c0.1,0.2,0.1,0.4,0.3,0.6s0.3,0.3,0.4,0.4L326.6,409.4z"></path>
						<path className="st45 IWcSNwFC_93" d="M326.7,411c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0,0.4-0.1,0.6
				c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.3-0.4-0.5C326.6,411.4,326.6,411.2,326.7,411z"></path>
					</g>
					<g>
						<path className="st45 IWcSNwFC_94" d="M397.4,616.8l-2.6-1.2l-2.5,0.9l-0.4-1l9.5-3.3l0.4,1l-5.7,2l7.7,3.5l0.4,1.1l-5.7-2.6l-3.6,6.2l-0.4-1.2
				L397.4,616.8z"></path>
						<path className="st45 IWcSNwFC_95" d="M403.3,627c-0.3,0.7-0.9,1.2-1.8,1.5l-4.4,1.6l-0.3-0.9l1.1-0.4c-0.4-0.1-0.8-0.3-1.2-0.6
				c-0.4-0.3-0.6-0.8-0.8-1.4c-0.3-0.8-0.3-1.5-0.1-2.1c0.2-0.6,0.6-1,1.3-1.2c0.6-0.2,1.2-0.2,1.7,0.1c0.5,0.3,1,0.9,1.3,1.9
				l0.8,2.3l0.4-0.2c0.6-0.2,1-0.6,1.2-1s0.2-1,0-1.7c-0.2-0.5-0.4-0.9-0.7-1.2s-0.6-0.6-1-0.9l0.6-0.7c0.4,0.3,0.8,0.6,1.2,1
				c0.3,0.4,0.6,0.9,0.8,1.5C403.6,625.5,403.6,626.3,403.3,627z M397.5,627.8c0.4,0.3,0.8,0.5,1.4,0.5l1.2-0.4l-0.8-2.2
				c-0.4-1.2-1.1-1.7-1.9-1.4c-0.4,0.1-0.7,0.4-0.8,0.8c-0.1,0.4-0.1,0.9,0.1,1.5C396.8,627.1,397.1,627.5,397.5,627.8z"></path>
						<path className="st45 IWcSNwFC_96" d="M394.8,631.6c0-0.3,0-0.6,0.1-0.8l0.8,0.1c-0.1,0.4-0.1,0.8,0,1.2c0.1,0.4,0.3,0.6,0.6,0.8
				c0.3,0.1,0.7,0.1,1.1,0l7.6-2.7l0.3,1l-7.6,2.7c-0.7,0.2-1.3,0.2-1.8,0c-0.5-0.2-0.9-0.7-1.1-1.3
				C394.8,632.2,394.8,631.9,394.8,631.6z M406.9,629.5c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.5,0c0.2,0.1,0.3,0.2,0.4,0.4
				s0.1,0.4,0,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.2-0.4-0.4C406.8,629.9,406.8,629.7,406.9,629.5z
				"></path>
						<path className="st45 IWcSNwFC_97" d="M407.4,638.7c-0.3,0.7-0.9,1.1-1.8,1.5l-4.4,1.6l-0.3-0.9l1.1-0.4c-0.4-0.1-0.8-0.3-1.2-0.6
				c-0.4-0.3-0.6-0.8-0.8-1.4c-0.3-0.8-0.3-1.5-0.1-2.1c0.2-0.6,0.6-1,1.3-1.2c0.6-0.2,1.2-0.2,1.7,0.1c0.5,0.3,1,0.9,1.3,1.9
				l0.8,2.3l0.4-0.2c0.6-0.2,1-0.6,1.2-1c0.2-0.5,0.2-1-0.1-1.7c-0.2-0.5-0.4-0.9-0.7-1.2c-0.3-0.4-0.6-0.6-1-0.9l0.6-0.7
				c0.4,0.3,0.8,0.6,1.2,1c0.3,0.4,0.6,0.9,0.8,1.5C407.7,637.2,407.7,638,407.4,638.7z M401.6,639.5c0.4,0.3,0.8,0.5,1.4,0.5
				l1.2-0.4l-0.8-2.2c-0.4-1.2-1.1-1.7-1.9-1.4c-0.4,0.1-0.7,0.4-0.8,0.8c-0.1,0.4-0.1,0.9,0.1,1.5
				C401,638.8,401.2,639.2,401.6,639.5z"></path>
						<path className="st45 IWcSNwFC_98" d="M404.7,649.1l-0.8,0.3l-2-5.8l0.6-0.2l7.2,2.5l-1.6-4.4l0.8-0.3l2,5.6l-0.6,0.2l-7.3-2.5L404.7,649.1z"></path>
						<path className="st45 IWcSNwFC_99" d="M413,654.7c-0.3,0.7-1,1.2-2,1.6l-4.1,1.5l-0.3-1l4-1.4c0.7-0.3,1.2-0.6,1.5-1.2c0.3-0.5,0.3-1.1,0-1.8
				c-0.3-0.8-0.7-1.3-1.3-1.6s-1.3-0.3-2.1,0l-3.7,1.3l-0.3-1l7.1-2.5l0.3,0.9l-1.3,0.5c0.5,0.1,1,0.4,1.4,0.7
				c0.4,0.4,0.7,0.9,0.9,1.5C413.3,653.2,413.3,654,413,654.7z"></path>
						<path className="st45 IWcSNwFC_100" d="M417.2,664.3l-7.1,2.5l-0.3-0.9l1.3-0.5c-0.5-0.1-1-0.3-1.4-0.7c-0.4-0.4-0.7-0.8-0.9-1.4
				c-0.3-0.9-0.3-1.8,0-2.5s1-1.3,2-1.6l4.1-1.5l0.3,1l-4,1.4c-0.7,0.3-1.2,0.7-1.5,1.2c-0.3,0.5-0.3,1.1,0,1.8
				c0.3,0.7,0.7,1.3,1.3,1.5c0.6,0.3,1.3,0.3,2.1,0l3.7-1.3L417.2,664.3z"></path>
						<path className="st45 IWcSNwFC_101" d="M419.4,673c-0.3,0.7-1,1.2-2,1.6l-4.1,1.5l-0.3-1l4-1.4c0.7-0.3,1.2-0.6,1.5-1.2c0.3-0.5,0.3-1.1,0-1.8
				c-0.3-0.8-0.7-1.3-1.3-1.6c-0.6-0.3-1.3-0.3-2.1,0l-3.7,1.3l-0.3-1l7.1-2.5l0.3,0.9l-1.3,0.5c0.5,0.1,1,0.4,1.4,0.7
				c0.4,0.4,0.7,0.9,0.9,1.5C419.8,671.5,419.8,672.3,419.4,673z"></path>
						<path className="st45 IWcSNwFC_102" d="M421.4,676.1l0.3,1l-7.1,2.5l-0.3-1L421.4,676.1z M423.1,675.5c0.1-0.2,0.2-0.3,0.4-0.4
				c0.2-0.1,0.4-0.1,0.5,0s0.3,0.2,0.4,0.4s0.1,0.4,0,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0
				c-0.2-0.1-0.3-0.2-0.4-0.4C423,675.9,423,675.7,423.1,675.5z"></path>
						<path className="st45 IWcSNwFC_103" d="M417.3,686.5c0-0.7,0.1-1.3,0.4-1.8l0.9,0.1c-0.2,0.5-0.3,1-0.3,1.7c0,0.6,0.1,1.3,0.3,1.9
				c0.3,0.8,0.7,1.4,1.1,1.7c0.5,0.3,0.9,0.4,1.4,0.2c0.4-0.1,0.6-0.4,0.8-0.7c0.1-0.3,0.2-0.7,0.2-1s0-0.9-0.1-1.5
				c-0.1-0.8-0.1-1.4-0.1-1.9c0-0.5,0.1-0.9,0.4-1.4c0.2-0.4,0.6-0.7,1.2-0.9c0.5-0.2,1-0.2,1.4-0.1c0.5,0.1,0.9,0.4,1.3,0.8
				c0.4,0.4,0.8,1,1,1.8c0.2,0.5,0.3,1.1,0.3,1.7c0,0.6,0,1.1-0.1,1.6l-0.9-0.1c0.1-0.5,0.2-1,0.1-1.5c0-0.5-0.1-1-0.3-1.4
				c-0.3-0.8-0.7-1.4-1.1-1.7c-0.5-0.3-1-0.4-1.5-0.2c-0.4,0.1-0.6,0.4-0.8,0.7c-0.1,0.3-0.2,0.7-0.2,1.1c0,0.4,0,0.9,0.1,1.5
				c0.1,0.8,0.1,1.4,0.1,1.9c0,0.5-0.1,0.9-0.4,1.4c-0.2,0.4-0.6,0.7-1.2,0.9c-0.5,0.2-1,0.2-1.4,0.1c-0.5-0.1-0.9-0.4-1.4-0.9
				c-0.4-0.5-0.8-1.1-1-1.8C417.5,687.9,417.3,687.2,417.3,686.5z"></path>
						<path className="st45 IWcSNwFC_104" d="M421.5,697.7c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.5-0.4-0.8c-0.2-0.7-0.2-1.2,0-1.7
				c0.2-0.5,0.7-0.8,1.3-1.1l4.3-1.5l-0.4-1.3l0.8-0.3l0.4,1.3l1.6-0.5l0.3,1l-1.6,0.5l0.8,2.2l-0.8,0.3l-0.8-2.2l-4.2,1.5
				c-0.4,0.2-0.7,0.4-0.9,0.7s-0.1,0.6,0,1c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.4L421.5,697.7z"></path>
						<path className="st45 IWcSNwFC_105" d="M421.7,699.3c0.1-0.2,0.2-0.3,0.5-0.4s0.4-0.1,0.6,0c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0,0.6
				c-0.1,0.2-0.2,0.3-0.4,0.4s-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.2-0.4-0.4C421.6,699.7,421.6,699.5,421.7,699.3z"></path>
					</g>
					<path className="st44 IWcSNwFC_106" d="M465.2,429.2A7.6,7.6 0,1,1 480.40000000000003,429.2A7.6,7.6 0,1,1 465.2,429.2"></path>
					<path className="st45 IWcSNwFC_108" d="M80.39999999999999,302A4.4,4.4 0,1,1 89.2,302A4.4,4.4 0,1,1 80.39999999999999,302"></path>
					<path className="st44 IWcSNwFC_109" d="M455.2,392.7A17.6,17.6 0,1,1 490.40000000000003,392.7A17.6,17.6 0,1,1 455.2,392.7"></path>
					<g id="Group_134_1_" transform="translate(-11600.925 -7464.288)">
						<path id="Path_111_1_" className="st16 IWcSNwFC_110" d="M12082.5,7860.5c0,2.8-2.3,5.1-5.1,5.1c0,0,0,0,0,0h-5.3l2.7-2.9l1-1.1
				c0.6-0.6,0.9-1.5,0.9-2.4v-0.2c0-1.5-1-2.8-2.4-3.3v-1.6c0-1.9-1.5-3.4-3.4-3.4c-1,0-1.9,0.4-2.5,1.1l-1,1.1v-3.6
				c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0h7.8c2.8,0,5.1,2.3,5.1,5.1c0,1-0.3,2-0.9,2.9C12081.7,7857.3,12082.5,7858.9,12082.5,7860.5
				L12082.5,7860.5z"></path>
						<g id="Group_133_1_" transform="translate(11600.925 7494.874)">
							<path id="Path_16_1_" className="st46 IWcSNwFC_111" d="M473.1,364.2v0.2c0,0.2-0.1,0.3-0.2,0.4l-1,1.1l-4.4,4.6c-0.1,0.1-0.2,0.1-0.3,0.2
					c-0.1,0-0.1,0-0.2,0h0c-0.3,0-0.6-0.3-0.6-0.6c0,0,0,0,0,0v-3.6c0-0.3-0.3-0.6-0.6-0.6h-1.1c-0.3,0-0.6-0.3-0.6-0.6v-0.1
					c0-0.2,0.1-0.3,0.2-0.4l1-1.1l1.3-1.4l3-3.2c0.2-0.3,0.6-0.3,0.9,0c0.1,0.1,0.2,0.3,0.2,0.5v3.6c0,0.3,0.3,0.6,0.6,0.6
					c0,0,0,0,0,0h1.1C472.8,363.6,473.1,363.9,473.1,364.2z"></path>
						</g>
					</g>
					<path className="st44 IWcSNwFC_112" d="M476.7,414.2l-3.1,3.1c-0.5,0.5-1.2,0.5-1.7,0l-3.1-3.1c-0.8-0.8-0.2-2.1,0.9-2.1h6.1
			C477,412.2,477.5,413.5,476.7,414.2z"></path>
				</g>
			</g>
			</svg>)
			}
		 </div>
	)

}


export default MySvg;