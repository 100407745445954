import React from "react";
import "./style.scss";
import { useState, useEffect, useMemo } from "react";
import image1 from "../../assets/images/newsImages/tirza-van-dijk-.png";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import arrow from '../../assets/images/arrow-up-right.png'
import { API_ENDPOINT } from "../../config";

const News = () => {
  const [html, setHTML] = useState([]);
  const [imageData, setImageData] = useState()


  const fetchHtml = useMemo(() => async () => {
    const response = await fetch(`${API_ENDPOINT}/api/blog?take=2&skip=0`);
    const data = await response.json();
  

    return data;
  }, []);
  console.log(imageData,'imagedata');
  useEffect(() => {
    fetchHtml().then(data => setHTML(data));


  }, [fetchHtml]);

  return (
    <section className="L-news-container G-flex G-justify-center G-align-center" id="news">
      <div className="G-container ">

        <div className="L-news-wrapper G-flex ">
          <div className="L-txt-box G-flex ">
            <div className="text-news-container">
              <div className=" L-news-logo ">
                <div className="L-logo-box ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="flash-svg"><path d="M20.608 12.463v.34c0 .366-.134.719-.388.987l-2.344 2.499-9.922 10.565a1.428 1.428 0 0 1-.628.388c-.134.05-.276.07-.424.07h-.007c-.797 0-1.44-.649-1.44-1.44v-8.144c0-.797-.642-1.44-1.44-1.44H1.44c-.79 0-1.44-.642-1.44-1.44v-.338c0-.367.141-.72.395-.988l2.35-2.498 2.979-3.17L12.668.46c.275-.29.65-.459 1.052-.459.79 0 1.44.65 1.44 1.44v8.144c0 .797.642 1.44 1.44 1.44h2.568c.797 0 1.44.642 1.44 1.44Z" fill="#f9c800" fillRule="evenodd" data-name="Path 6" /></svg>
                </div>
                <p className="Pages-greeting">Tech insights</p>

              </div>
              <div className="L-txt">
                <h2 className="Pages-Title">News</h2>
                <p className="Pages-description news-description">Welcome to Beetee News Center, your go-to source for the latest updates, insights, and trends from the world of technology. Our team of experts curates and publishes articles on a regular basis to keep you informed and up-to-date on the latest developments in the IT industry.</p>
              </div>

            </div>
          </div>
          <div className="L-contain">
            <div className="L-data-container">
              <div className="data-div">
                {
                  html.map((el) => {
                    return (
                      <div className="L-box " key={el.id}>
                        <div style={{ backgroundImage: `url('https://beeteeapi.azurewebsites.net/${el.imageUrl}')` }}
                          className="L-box-img L-left G-background-cover  G-flex G-align-end" >
                          <HashLink to={`/inside/${el.path}?param=main`} className={`cirqle G-flex G-align-center G-justify-center G-background-contain left-cirql `} style={{ backgroundImage: `url('${arrow}')` }} >  </HashLink>
                        </div>
                        <div className="L-text-cont G-text-align-start G-flex-column G-flex G-justify-between">
                          <p className="L-box-data">{el.readDuration}</p>
                          <h4 className="individual">{el.title}</h4>
                          <p style={{ marginTop: '2%' }} className='Pages-description'>{el.description}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className="button-div">
                <HashLink to='/Blog' className="select  G-flex G-justify-center G-align-center" >MORE ARTICLES</HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>);
};

export default News;


