import "./style.scss";
import aboutImage from '../../assets/images/newsImages/apple2.jpg';
import flashimageReverse from '../../assets/images/servicePage/background-element-2.svg'



function Meet() {
  return (
    <section className="L-meet-container G-position-sticky sizing section  G-disable-sticky" id="about">
      <div className="background-about-image" style={{ backgroundImage: `url(${flashimageReverse})` }}>
        <div className="G-container">
          <div className="L-meet-wrapper G-flex ">
            <div className="L-text-container G-flex ">
              <div className="L-logo-container  ">
                <div className="L-logo-box ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="flesh-svg"><path d="M20.608 12.463v.34c0 .366-.134.719-.388.987l-2.344 2.499-9.922 10.565a1.428 1.428 0 0 1-.628.388c-.134.05-.276.07-.424.07h-.007c-.797 0-1.44-.649-1.44-1.44v-8.144c0-.797-.642-1.44-1.44-1.44H1.44c-.79 0-1.44-.642-1.44-1.44v-.338c0-.367.141-.72.395-.988l2.35-2.498 2.979-3.17L12.668.46c.275-.29.65-.459 1.052-.459.79 0 1.44.65 1.44 1.44v8.144c0 .797.642 1.44 1.44 1.44h2.568c.797 0 1.44.642 1.44 1.44Z" fill="#f9c800" fillRule="evenodd" data-name="Path 6" /></svg>
                </div>
                <p className="Pages-greeting">About Us</p>
              </div>
              <h2 className="Pages-Title">We Love <br /> What We Do</h2>
              <p className="Pages-description">
                At Beetee, we are passionate about technology and dedicated to providing the best solutions for your business. Our team of experienced professionals is committed to conducting business with integrity, innovation, and quality. We value reliability and customer-centricity, and we strive to put the needs of our customers first. Our goal is to help your business thrive through our expert IT services and consulting.
              </p>

            </div>
           
            <p className="Pages-description mobile-description">
              At Beetee, we are passionate about technology and dedicated to providing the best solutions for your business. Our team of experienced professionals is committed to conducting business with integrity, innovation, and quality. We value reliability and customer-centricity, and we strive to put the needs of our customers first. Our goal is to help your business thrive through our expert IT services and consulting.</p>
              <div className="L-image-container">
              <img src={aboutImage} alt="" className="L-about-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Meet;