import './style.scss';
import In from '../../assets/images/footherImages/likedin.svg';
import blackIn from '../../assets/images/footherImages/blacklinkedin.png';
import { useBottomActiveId } from '../../hook/useBottomRmClass';

let inLogo;
let txtstyle;

function Foother() {
    const bottomActiveId = useBottomActiveId()

    if (bottomActiveId === 'home' || bottomActiveId === 'service' || bottomActiveId === 'contact' || bottomActiveId == 'about') {
        inLogo = blackIn
        txtstyle = ' L-sp  dark'
    }
    else if (bottomActiveId === 'news') {
        inLogo = In
        txtstyle = 'L-sp light'
    }

    let child = window.pageYOffset

    return (
        <div className="L-foother-container ">
            <div className="G-container">
                <div className="L-foother G-flex G-justify-between">
                    <div className="L-left-box">

                        <div className={child <= 10 ? 'none' : 'blok'}>
                            <a className='links' target='_blank' href="https://www.linkedin.com/company/beeteelabs/"><img src={inLogo} alt="" /></a>

                        </div>
                        <div className={child > 10 ? 'none' : 'blok'}>
                            <a className='links' target='_blank' href="https://www.linkedin.com/company/beeteelabs/"><img src={blackIn} alt="" /></a>
                        </div>
                    </div>
                    <div className="L-right-box">
                        <span className={ 'L-sp' + ' ' + txtstyle}>Copyright &copy; 2023</span>
                        <span> <a className="infoBeetee foother-href" href="mailto:info@beetee.am">info@beetee.am</a></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Foother;