import './style.scss';
import arrow from '../../assets/images/arrow-up-right.png'
import { HashLink } from 'react-router-hash-link';

function Cirqle({className,path,params}){
  
    return (
     
            <HashLink  to={`/inside/${path}?params=blog`}  className={`cirqle G-flex G-align-center G-justify-center G-background-contain ${className}`} style={{backgroundImage:`url('${arrow}')`}} >  </HashLink>
  
    )
}

export default Cirqle;