import "./App.css";
import "./assets/styles/global.scss";
import "./assets/styles/index.scss";
import { useEffect, useState } from "react";
import RouteNews from "./pages/RoutingPages/Newspage";
import { Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/main";
import MyScroll from "./pages/scroll";
import Welcome from "./pages/welcomePage";
import InsideNews from "./pages/RoutingPages/Inside/Inside";
import Header from "./components/header";
import usePreloader from "./hook/usePreloader";
import AdminPage from "./components/ADMIN";



function App() {
  const [top, setTop] = useState(false)
  const location = useLocation();
  const { isLoading } = usePreloader();
  useEffect(() => {
    setTimeout(() => {
      setTop(true)
    })

    if (top) {
      window.scrollTo({ top: 1 })
    }
  }, [location.pathname])

  if (isLoading) {
    return <Welcome />
  }

  
  return (
    <>
      <div className="App" >
        <Header />
        <div className="G-container L-scroll-container " >
          <MyScroll />
        </div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Blog" element={<RouteNews />} />
          <Route path="/inside/:path" element={<InsideNews />} />
          <Route path ="/admin" element= {<AdminPage/> } />
        </Routes>
      </div>

    </>
  );
}

export default App;
