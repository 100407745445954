import React, { useLayoutEffect } from "react";
import "./style.scss";
import Logo from "../../assets/images/Beetee_logo.svg";
import blueLogo from '../../assets/images/Beetee_blue_logo.svg';
import { useActiveId } from "../../hook/useTopRmClass";
import { useState, useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css';
import In from '../../assets/images/footherImages/linkedin.png'
import linkedin from '../../assets/images/footherImages/likedin.svg';
import blackIn from '../../assets/images/footherImages/blacklinkedin.png'
import { Link } from "react-router-dom";
import close from '../../assets/images/headerImages/close.svg'



const Header = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const activeId = useActiveId()
  const ul = document.querySelectorAll('ul')
  let myClass = 'L-nav-box G-flex'
  let gmailclass = 'gmail'
  let visibl
  let inLogo;
  let buttonStyle;

  if (activeId === 'home' || activeId === 'contact' || activeId === 'service' || activeId == 'about') {
    myClass += ' blackcolor'
    gmailclass += ' blackcolor'
    inLogo = blackIn
    visibl = blueLogo
    buttonStyle = 'Hamburger black'

    if (isOpen === true) {
      visibl = Logo
    }
  }
  else if (activeId === 'news') {
    myClass += ' whitecolor'
    gmailclass += ' whitecolor'
    visibl = Logo
    inLogo = linkedin
    buttonStyle = 'Hamburger white'
  }

  else {
    myClass += ' blackcolor'
    gmailclass += ' blackcolor'
    inLogo = blackIn
    visibl = blueLogo
    buttonStyle = 'Hamburger black'
  }

  const buttonOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  let child = window.pageYOffset;

  if (isOpen) {
    document.body.style.overflow = 'hidden';
    inLogo = In
    buttonStyle = 'white'
    visibl=Logo
    
  } else {
    document.body.style.overflow = 'visible'
  }
  child += 1
//routing + scroll to anchor 
  function scrollToAnchor(anchorId) {
    const element = document.getElementById(anchorId);
    const yOffset = 0;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  
  }

  return (

    <div className={`L-header-wrapper WOW animate__animated animate__fadeInDown G-flex G-align-center ${isOpen ? 'blueheader' : ''}  ${className}`}>
      <div className="G-container flex-container">
        <div className="L-header-container G-flex G-justify-between  G-align-center">
          <div className="L-mail-container">
            <a className={gmailclass} href="mailto:info@beetee.am">info@beetee.am</a>
            <div className="L-links-container" >
              <div className={child <= 0.5 ? 'none' : 'blok G-flex G-justify-between'}>
                <a className='links' target='blank' href="https://www.linkedin.com/company/beeteelabs/"><img src={inLogo} alt="" /></a>
              </div>
              <div className={child > 0.5 ? 'none' : 'blok G-flex G-justify-between'}>
                <a className='links' target='blank' href="https://www.linkedin.com/company/beeteelabs/"><img src={blackIn} alt="" /></a>
              </div>
            </div>
          </div>
          <div className="L-logo-container">
            <div className="L-logo ">
              <Link to='/' onClick={()=>setTimeout(()=>{
                scrollToAnchor('home')
              },200)} className="logo-link" >
                <img className={child <= 0.5 ? 'none' : 'blok'} src={visibl} alt=" Logo" />
                <img className={child > 0.5 ? 'none' : 'blok'} src={blueLogo} alt='logo' />
              </Link>
            </div>
          </div>
          <div className="L-nav-container">
            <button className={buttonStyle} onClick={buttonOpen} >
              {isOpen ? <div className="hamburger-icon"><img src={close} alt="close" /></div> : <div className="hamburger-icon"> <p className="columns">|||</p> </div>}
            </button>
            {isOpen && (
              <nav id="popup" className='L-mobile-nav' >
                  <ul >
              <li> <Link to='/'  onClick={()=> setTimeout(() => {
                scrollToAnchor('home')
                setIsOpen(false)

              }, 200)} className={activeId === 'home' ? 'active links' : 'links'}>Welcome</Link> </li>
              <li> <Link to='/' onClick={()=> setTimeout(()=>{
                scrollToAnchor('service')
                setIsOpen(false)
              },200)} className={activeId === 'service' ? 'active links' : 'links'} >Service</Link> </li>
              <li> <Link to='/' onClick={()=>setTimeout(()=>{
                scrollToAnchor('news')
                setIsOpen(false)

              },200)}  className={activeId === 'news' || activeId === 'inside' || activeId==='Blog' ? 'active links' : 'links'} >News</Link> </li>
              <li> <Link to='/' onClick={()=> setTimeout(() => {
                scrollToAnchor('about')
                setIsOpen(false)

              }, 200)}  className={activeId === 'about' ? 'active links' : 'links'} >About</Link> </li>
              <li> <Link to='/' onClick={()=> setTimeout(() => {
                scrollToAnchor('contact')
                setIsOpen(false)


              }, 200)}  className={activeId === 'contact' ? 'active links' : 'links'} >Contact</Link> </li>
        
            </ul>
                <a className="infoBeetee" href="mailto:info@beetee.am">info@beetee.am</a>
              </nav>
            )}
            <ul className={myClass}>
              <li> <Link to='/'  onClick={()=> setTimeout(() => {
                scrollToAnchor('home')
              }, 200)} className={activeId === 'home' ? 'active links' : 'links'}>Welcome</Link> </li>
              <li> <Link to='/' onClick={()=> setTimeout(()=>{
                scrollToAnchor('service')
              },200)} className={activeId === 'service' ? 'active links' : 'links'} >Service</Link> </li>
              <li> <Link to='/' onClick={()=>setTimeout(()=>{
                scrollToAnchor('news')
              },200)}  className={activeId === 'news' || activeId === 'inside' || activeId==='Blog' ? 'active links' : 'links'} >News</Link> </li>
              <li> <Link to='/' onClick={()=> setTimeout(() => {
                scrollToAnchor('about')
              }, 200)}  className={activeId === 'about' ? 'active links' : 'links'} >About</Link> </li>
              <li> <Link to='/' onClick={()=> setTimeout(() => {
                scrollToAnchor('contact')
              }, 200)}  className={activeId === 'contact' ? 'active links' : 'links'} >Contact</Link> </li>
        
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};




export default Header;
