



export const servicedata = [
  {
    id: 0,
    visible: false,
    img: <svg className="ico" id="Custom_Product_Development" data-name="Custom Product Development" xmlns="http://www.w3.org/2000/svg" width="81.521" height="86.336" viewBox="0 0 81.521 86.336">
    <path id="Path_19" data-name="Path 19" d="M191.124,1906.714h-56.4a6.154,6.154,0,0,0-6.147,6.147v25.992A6.154,6.154,0,0,0,134.72,1945h56.4a6.152,6.152,0,0,0,6.144-6.147v-25.992A6.153,6.153,0,0,0,191.124,1906.714Zm4.363,32.14a4.369,4.369,0,0,1-4.363,4.366h-56.4a4.37,4.37,0,0,1-4.366-4.366v-25.992a4.37,4.37,0,0,1,4.366-4.366h56.4a4.369,4.369,0,0,1,4.363,4.366Z" transform="translate(-121.238 -1858.665)" fill="#0af"/>
    <path id="Path_20" data-name="Path 20" d="M210.5,1920.579H197a4.024,4.024,0,0,0-4.019,4.019v.735a4.024,4.024,0,0,0,4.019,4.019h13.5a4.024,4.024,0,0,0,4.019-4.019v-.735A4.024,4.024,0,0,0,210.5,1920.579Zm1.954,4.755a1.956,1.956,0,0,1-1.954,1.954H197a1.956,1.956,0,0,1-1.954-1.954v-.735a1.956,1.956,0,0,1,1.954-1.954h13.5a1.956,1.956,0,0,1,1.954,1.954Z" transform="translate(-162.066 -1867.454)" fill="#0af"/>
    <path id="Path_21" data-name="Path 21" d="M152.5,1838.926a.35.35,0,0,1-.332.241h-5.231a4.323,4.323,0,0,0-4.316,4.32v3.214h19.626v-1.084a9.719,9.719,0,0,1,.294-2.373,9.579,9.579,0,0,1,4.4-5.87,9.392,9.392,0,0,1,2.512-1.042,9.656,9.656,0,0,1,4.741,0,9.438,9.438,0,0,1,2.519,1.045,9.564,9.564,0,0,1,4.694,8.241v1.084h19.622v-3.214a4.325,4.325,0,0,0-4.32-4.32H191.48a.354.354,0,0,1-.331-.238,19.875,19.875,0,0,0-.929-2.245.354.354,0,0,1,.062-.407l3.7-3.7a4.315,4.315,0,0,0,0-6.108l-3.009-3.009a4.325,4.325,0,0,0-6.11,0l-3.7,3.7a.352.352,0,0,1-.407.06,20.708,20.708,0,0,0-2.245-.927.355.355,0,0,1-.241-.332v-5.235a4.321,4.321,0,0,0-4.316-4.316h-4.261a4.323,4.323,0,0,0-4.316,4.32v5.231a.356.356,0,0,1-.241.331,20.467,20.467,0,0,0-2.249.93.353.353,0,0,1-.407-.062l-3.7-3.7a4.292,4.292,0,0,0-3.054-1.266h0a4.281,4.281,0,0,0-3.048,1.266l-3.013,3.009a4.321,4.321,0,0,0,0,6.106l3.7,3.7a.356.356,0,0,1,.06.409A20.492,20.492,0,0,0,152.5,1838.926Zm2.328-4.109-3.7-3.7a2.255,2.255,0,0,1,0-3.185l3.015-3.01a2.23,2.23,0,0,1,1.588-.66h0a2.239,2.239,0,0,1,1.594.661l3.7,3.7a2.424,2.424,0,0,0,2.766.456,18.469,18.469,0,0,1,2.024-.836,2.424,2.424,0,0,0,1.63-2.282v-5.231a2.255,2.255,0,0,1,2.251-2.255h4.261a2.253,2.253,0,0,1,2.251,2.251v5.235a2.424,2.424,0,0,0,1.631,2.284,18.28,18.28,0,0,1,2.019.833,2.414,2.414,0,0,0,2.769-.457l3.7-3.7a2.256,2.256,0,0,1,3.188,0l3.009,3.009a2.249,2.249,0,0,1,0,3.185l-3.7,3.7a2.421,2.421,0,0,0-.458,2.773,17.829,17.829,0,0,1,.835,2.016,2.422,2.422,0,0,0,2.282,1.626h5.231a2.257,2.257,0,0,1,2.254,2.255v1.149H183.433a11.662,11.662,0,0,0-8.727-10.306,11.718,11.718,0,0,0-5.764,0,11.473,11.473,0,0,0-3.056,1.269,11.593,11.593,0,0,0-5.665,9.037H144.685v-1.149a2.256,2.256,0,0,1,2.251-2.255h5.231a2.422,2.422,0,0,0,2.283-1.63,18.514,18.514,0,0,1,.835-2.016A2.421,2.421,0,0,0,154.827,1834.816Z" transform="translate(-130.143 -1801.417)" fill="#f9c800"/>
    <path id="Path_22" data-name="Path 22" d="M295.813,1815.543a1.332,1.332,0,0,0,.362.161,1.3,1.3,0,0,0,.322.041,1.2,1.2,0,0,0,.3-.038l7.217-1.806a1.254,1.254,0,0,0,.757-.543l.077-.12a1.26,1.26,0,0,0-.377-1.74l-.844-.544,1.794-2.789a1.26,1.26,0,0,0-1.364-1.9l-7.22,1.8a1.255,1.255,0,0,0-.757.543l-.077.12a1.261,1.261,0,0,0,.377,1.741l.844.542-1.794,2.79A1.261,1.261,0,0,0,295.813,1815.543Zm2.561-3.869a1.257,1.257,0,0,0-.377-1.74l-.844-.543.031-.048,7.044-1.76-1.744,2.71a1.261,1.261,0,0,0,.378,1.74l.844.543-.031.048-7.045,1.763Z" transform="translate(-226.889 -1794.961)" fill="#f9c800"/>
    <path id="Path_23" data-name="Path 23" d="M178.854,1783.245a1.036,1.036,0,0,0,1.368.526l.351-.156.745,1.677a1.035,1.035,0,0,0,1.37.526,1.083,1.083,0,0,0,.274-.177,1.036,1.036,0,0,0,.284-.429l1.539-4.407a1.028,1.028,0,0,0-.031-.765l-.037-.083a1.038,1.038,0,0,0-1.368-.527l-.351.156-.745-1.677a1.037,1.037,0,0,0-1.927.079l-1.54,4.407a1.035,1.035,0,0,0,.031.767Zm2.483-4.138.568,1.279a1.036,1.036,0,0,0,1.368.526l.086-.038-1.126,3.225-.569-1.281a1.037,1.037,0,0,0-.949-.616,1.027,1.027,0,0,0-.419.089l-.086.038Z" transform="translate(-153.032 -1776.622)" fill="#0af"/>
    <path id="Path_24" data-name="Path 24" d="M258.18,1782.221a.65.65,0,0,0,.134.013.662.662,0,0,0,.647-.528l.395-1.915a.452.452,0,0,1,.436-.335l.806.067a1.774,1.774,0,0,0,1.844-1.4l.366-1.876a.661.661,0,1,0-1.3-.253l-.366,1.875a.453.453,0,0,1-.437.338l-.806-.067a1.777,1.777,0,0,0-1.841,1.386l-.395,1.916A.661.661,0,0,0,258.18,1782.221Z" transform="translate(-203.066 -1775.456)" fill="#0af"/>
    <path id="Path_25" data-name="Path 25" d="M128.6,1823.286l2.306.492a.72.72,0,0,1,.529.713l-.1.993a2.041,2.041,0,0,0,1.551,2.134l2.355.529a.661.661,0,0,0,.29-1.291l-2.356-.529a.722.722,0,0,1-.524-.711l.1-.993a2.036,2.036,0,0,0-1.569-2.139l-2.306-.492a.661.661,0,1,0-.276,1.293Z" transform="translate(-120.92 -1804.947)" fill="#f9c800"/>
    <path id="Path_26" data-name="Path 26" d="M111.639,1854.615l-1-.074a2.032,2.032,0,0,0-2.1,1.625.661.661,0,0,0,.529.771.67.67,0,0,0,.122.011.661.661,0,0,0,.649-.541.71.71,0,0,1,.7-.548l1,.073a.661.661,0,1,0,.1-1.319Z" transform="translate(-108.536 -1825.587)" fill="#0af"/>
    <path id="Path_27" data-name="Path 27" d="M323.287,1853.936a.662.662,0,0,0-.935-.023,1.625,1.625,0,0,1-1.452.3l-1.009-.4a.661.661,0,0,0-.483,1.232l1.009.4a2.437,2.437,0,0,0,.886.156,2.925,2.925,0,0,0,1.961-.734A.661.661,0,0,0,323.287,1853.936Z" transform="translate(-241.948 -1825.077)" fill="#0af"/>
  </svg>
    ,
    title: `Custom Product  Development`,
    description: "From customization to innovation, cost-effectiveness, scalability, and competitive advantage, we can help your business drive growth and stay ahead of the curve! Our custom product development process is a collaborative journey that we take with our clients: Our team of experienced developers uses the latest tools and technologies to bring the product to life, carefully testing it every step of the way to ensure that it meets the highest standards of quality.   We understand that the success of the product doesn't end with its deployment- that's why we provide ongoing maintenance and support to ensure that the product remains up-to-date, secure, and bug-free. We are invested in our clients' success, and we work tirelessly to ensure that we have brought their vision to life in the most effective way possible."  
  },
  {
    id: 1,
    visible: false,
    img: <svg className="ico" xmlns="http://www.w3.org/2000/svg" width="76.042" height="79.852" viewBox="0 0 76.042 79.852">
    <g id="Group_112" data-name="Group 112" transform="translate(-1427 -1337)">
      <g id="Strategic_staffing" data-name="Strategic staffing" transform="translate(1427 1337)">
        <path id="Path_28" data-name="Path 28" d="M627.083,1569.93a9.266,9.266,0,0,1-.28,2.262,9.131,9.131,0,0,1-4.195,5.594,8.967,8.967,0,0,1-2.394.994,9.185,9.185,0,0,1-4.519,0,8.982,8.982,0,0,1-2.4-1,9.113,9.113,0,0,1-4.474-7.854V1568.9h-18.7v3.063a4.122,4.122,0,0,0,4.117,4.117h4.985a.337.337,0,0,1,.315.227,18.96,18.96,0,0,0,.886,2.139.338.338,0,0,1-.059.388l-3.526,3.526a4.111,4.111,0,0,0,0,5.821l2.868,2.867a4.122,4.122,0,0,0,5.823,0l3.524-3.524a.333.333,0,0,1,.388-.057,19.771,19.771,0,0,0,2.14.883.338.338,0,0,1,.23.316v4.989a4.118,4.118,0,0,0,4.114,4.114h4.061a4.12,4.12,0,0,0,4.114-4.117v-4.985a.339.339,0,0,1,.23-.315,19.527,19.527,0,0,0,2.144-.886.337.337,0,0,1,.388.059l3.524,3.524a4.089,4.089,0,0,0,2.911,1.207h0a4.079,4.079,0,0,0,2.905-1.206l2.872-2.868a4.118,4.118,0,0,0,0-5.82l-3.527-3.527a.339.339,0,0,1-.058-.389,19.625,19.625,0,0,0,.884-2.135.334.334,0,0,1,.316-.23h4.985a4.12,4.12,0,0,0,4.114-4.117V1568.9h-18.7Zm1.628,2.746a11.216,11.216,0,0,0,.3-1.81h14.806v1.095a2.15,2.15,0,0,1-2.145,2.149h-4.985a2.309,2.309,0,0,0-2.176,1.553,17.647,17.647,0,0,1-.8,1.921,2.307,2.307,0,0,0,.437,2.64l3.527,3.527a2.149,2.149,0,0,1,0,3.035l-2.873,2.869a2.126,2.126,0,0,1-1.514.629h0a2.135,2.135,0,0,1-1.519-.63l-3.527-3.527a2.311,2.311,0,0,0-2.636-.434,17.62,17.62,0,0,1-1.929.8,2.31,2.31,0,0,0-1.553,2.175v4.985a2.149,2.149,0,0,1-2.145,2.149h-4.061a2.148,2.148,0,0,1-2.145-2.145v-4.989a2.31,2.31,0,0,0-1.554-2.177,17.454,17.454,0,0,1-1.924-.794,2.3,2.3,0,0,0-2.64.436l-3.523,3.524a2.151,2.151,0,0,1-3.039,0l-2.868-2.867a2.143,2.143,0,0,1,0-3.036l3.527-3.527a2.307,2.307,0,0,0,.436-2.643,17.092,17.092,0,0,1-.8-1.922,2.308,2.308,0,0,0-2.175-1.55h-4.985a2.151,2.151,0,0,1-2.149-2.149v-1.095h14.8a11.123,11.123,0,0,0,5.4,8.61,10.93,10.93,0,0,0,2.919,1.211,11.172,11.172,0,0,0,5.494,0,10.919,10.919,0,0,0,2.913-1.209,11.1,11.1,0,0,0,5.1-6.8Z" transform="translate(-579.752 -1517.915)" fill="#0af"/>
        <path id="Path_29" data-name="Path 29" d="M671.464,1427.405l.359.07-.335,1.717a.988.988,0,0,0,.78,1.158.94.94,0,0,0,.193.019c.039,0,.078,0,.121-.006a1,1,0,0,0,.456-.179l3.608-2.6a.982.982,0,0,0,.4-.616l.016-.084a.99.99,0,0,0-.78-1.159l-.359-.07.335-1.716a.988.988,0,0,0-.78-1.159,1,1,0,0,0-.767.167l-3.612,2.6a.985.985,0,0,0-.394.615l-.016.083a.988.988,0,0,0,.78,1.159Zm3.42-3.032-.255,1.309a.99.99,0,0,0,.78,1.159l.088.017-2.641,1.9.256-1.311a.986.986,0,0,0-.78-1.158l-.088-.017Z" transform="translate(-632.197 -1422.763)" fill="#0af"/>
        <path id="Path_30" data-name="Path 30" d="M623.678,1462.551a.97.97,0,0,0,1.337.308l.268-.168.865,1.384a.971.971,0,0,0,1.337.308l.03-.02a1.044,1.044,0,0,0,.2-.182.971.971,0,0,0,.206-.433l.819-4.148a.963.963,0,0,0-.128-.705l-.044-.071a.972.972,0,0,0-1.337-.308l-.268.168-.865-1.384a.971.971,0,0,0-1.775.327l-.82,4.148a.963.963,0,0,0,.129.706Zm1.743-3.961.631,1.009a.971.971,0,0,0,1.337.308l.012-.008-.571,2.888-.632-1.01a.963.963,0,0,0-.6-.432.982.982,0,0,0-.219-.025.966.966,0,0,0-.513.148l-.011.007Z" transform="translate(-601.477 -1444.956)" fill="#0af"/>
        <path id="Path_31" data-name="Path 31" d="M702,1462.8a.639.639,0,0,0,.128.013.63.63,0,0,0,.616-.5l.376-1.826a.437.437,0,0,1,.415-.32l.768.063a1.688,1.688,0,0,0,1.757-1.337l.349-1.787a.63.63,0,1,0-1.237-.241l-.349,1.787a.43.43,0,0,1-.416.322l-.768-.064a1.691,1.691,0,0,0-1.755,1.321l-.376,1.826A.631.631,0,0,0,702,1462.8Z" transform="translate(-652.275 -1444.634)" fill="#0af"/>
        <path id="Path_32" data-name="Path 32" d="M579.349,1498.288l1.367.292a.2.2,0,0,1,.127.166l-.059.589a1.454,1.454,0,0,0,1.1,1.521l1.4.314a.63.63,0,0,0,.276-1.23l-1.4-.314a.2.2,0,0,1-.126-.166l.059-.589a1.451,1.451,0,0,0-1.117-1.525l-1.368-.292a.63.63,0,1,0-.263,1.233Z" transform="translate(-572.413 -1471.127)" fill="#0af"/>
        <path id="Path_33" data-name="Path 33" d="M605.643,1503.848a4.894,4.894,0,1,0-4.894-4.894A4.9,4.9,0,0,0,605.643,1503.848Zm0-7.82a2.926,2.926,0,1,1-2.926,2.926A2.929,2.929,0,0,1,605.643,1496.028Z" transform="translate(-586.672 -1469.186)" fill="#f9c800"/>
        <path id="Path_34" data-name="Path 34" d="M711,1498.953a4.894,4.894,0,1,0,4.894-4.894A4.9,4.9,0,0,0,711,1498.953Zm4.894-2.926a2.926,2.926,0,1,1-2.926,2.926A2.929,2.929,0,0,1,715.89,1496.028Z" transform="translate(-658.457 -1469.186)" fill="#f9c800"/>
        <path id="Path_35" data-name="Path 35" d="M656.779,1485.539a7.031,7.031,0,1,0-7.031-7.031A7.039,7.039,0,0,0,656.779,1485.539Zm0-12.093a5.062,5.062,0,1,1-5.062,5.063A5.068,5.068,0,0,1,656.779,1473.446Z" transform="translate(-618.577 -1454.482)" fill="#f9c800"/>
        <path id="Path_36" data-name="Path 36" d="M635.393,1531.107h-7.1a10.5,10.5,0,0,0-10.46-9.721,10.4,10.4,0,0,0-7.571,3.225,15.692,15.692,0,0,0-23.323,0,10.389,10.389,0,0,0-7.568-3.225,10.505,10.505,0,0,0-10.463,9.721h-7.458a1.049,1.049,0,1,0,0,2.1h73.943a1.049,1.049,0,1,0,0-2.1Zm-17.559-7.752a8.533,8.533,0,0,1,8.486,7.752H613.776a15.705,15.705,0,0,0-2.274-4.931A8.443,8.443,0,0,1,617.834,1523.355Zm-19.233-1.968a13.661,13.661,0,0,1,10.8,5.249l.078.1a13.714,13.714,0,0,1,2.252,4.371H585.475a13.772,13.772,0,0,1,2.27-4.391l.1-.134A13.658,13.658,0,0,1,598.6,1521.387Zm-19.23,1.968a8.437,8.437,0,0,1,6.334,2.825,15.7,15.7,0,0,0-2.277,4.927H570.881A8.535,8.535,0,0,1,579.371,1523.355Z" transform="translate(-560.4 -1485.699)" fill="#f9c800"/>
      </g>
    </g>
  </svg>
  
    ,
    title: 'Strategic Staffing',
    description: "Strategic staffing involves identifying and hiring the most qualified individuals to fill important roles within a company. Our strategic staffing services are designed to help businesses build highly effective teams that can boost productivity, streamline operations, and achieve their goals. Our team of staffing experts has extensive experience in the industry and uses the latest recruitment tools and techniques to attract the top talent. With our customized staffing solutions, we work closely with our clients to ensure that they have the right people in place to support their unique needs and challenges."

  },
  {
    id: 2,
    visible: false,
    img: <svg className="ico" id="Ui_UX_Design" data-name="Ui UX Design" xmlns="http://www.w3.org/2000/svg" width="96.649" height="78.884" viewBox="0 0 96.649 78.884">
    <path id="Path_50" data-name="Path 50" d="M349.591,1479.664a1.263,1.263,0,0,0-1.538-.956l-7.234,1.808a1.255,1.255,0,0,0-.756.542l-.078.122a1.264,1.264,0,0,0,.378,1.744l.846.544-1.8,2.794a1.261,1.261,0,0,0,.38,1.745,1.231,1.231,0,0,0,.686.2,1.221,1.221,0,0,0,.3-.037l7.23-1.809a1.254,1.254,0,0,0,.758-.544l.077-.12a1.263,1.263,0,0,0-.378-1.743l-.846-.544,1.8-2.794A1.251,1.251,0,0,0,349.591,1479.664Zm-3.118,3.083a1.262,1.262,0,0,0,.378,1.743l.846.544-.031.048-7.058,1.766,1.748-2.717a1.261,1.261,0,0,0-.378-1.744l-.846-.544.031-.048,7.056-1.763Z" transform="translate(-252.971 -1459.414)" fill="#f9c800"/>
    <path id="Path_51" data-name="Path 51" d="M173,1433.961a1.039,1.039,0,0,0,1.37.528l.352-.156.746,1.68a1.042,1.042,0,0,0,.949.617,1.048,1.048,0,0,0,.425-.091,1.092,1.092,0,0,0,.273-.176,1.04,1.04,0,0,0,.285-.429l1.541-4.415a1.034,1.034,0,0,0-.031-.767l-.036-.082a1.039,1.039,0,0,0-1.371-.527l-.351.156-.746-1.679a1.039,1.039,0,0,0-1.93.078l-1.543,4.417a1.034,1.034,0,0,0,.032.767Zm2.488-4.145.57,1.281a1.039,1.039,0,0,0,1.37.527l.087-.038-1.128,3.231-.57-1.284a1.039,1.039,0,0,0-1.37-.528l-.086.038Z" transform="translate(-147.637 -1427.327)" fill="#0af"/>
    <path id="Path_52" data-name="Path 52" d="M252.329,1432.939a.664.664,0,0,0,.783-.515l.4-1.919a.46.46,0,0,1,.437-.336l.808.067a1.784,1.784,0,0,0,1.847-1.406l.366-1.879a.662.662,0,1,0-1.3-.253l-.366,1.879a.451.451,0,0,1-.437.338l-.808-.067a1.778,1.778,0,0,0-1.844,1.388l-.4,1.919A.663.663,0,0,0,252.329,1432.939Z" transform="translate(-197.618 -1426.162)" fill="#0af"/>
    <path id="Path_53" data-name="Path 53" d="M111.526,1490.081l-2.36-.53a.724.724,0,0,1-.526-.713l.1-.995a2.039,2.039,0,0,0-1.572-2.142l-2.31-.493a.662.662,0,1,0-.277,1.3l2.311.493a.722.722,0,0,1,.53.714l-.1.995a2.045,2.045,0,0,0,1.553,2.138l2.36.53a.654.654,0,0,0,.146.016.663.663,0,0,0,.144-1.309Z" transform="translate(-104.057 -1463.545)" fill="#f9c800"/>
    <path id="Path_54" data-name="Path 54" d="M182.734,1608.189a1.629,1.629,0,0,1-1.454.3l-1.011-.4a.662.662,0,0,0-.484,1.233l1.011.4a2.447,2.447,0,0,0,.888.157,2.931,2.931,0,0,0,1.965-.735.662.662,0,1,0-.914-.959Z" transform="translate(-151.747 -1541.318)" fill="#0af"/>
    <path id="Path_55" data-name="Path 55" d="M227.09,1617.121H205.12a.892.892,0,0,0,0,1.785h21.97a.892.892,0,0,0,0-1.785Z" transform="translate(-167.492 -1547.09)" fill="#0af"/>
    <path id="Path_56" data-name="Path 56" d="M163.785,1490.621H180.9a4.183,4.183,0,0,0,4.178-4.178v-6.221a4.183,4.183,0,0,0-4.178-4.178H163.785a4.183,4.183,0,0,0-4.178,4.178v6.221A4.183,4.183,0,0,0,163.785,1490.621Zm-2.293-10.4a2.3,2.3,0,0,1,2.293-2.293H180.9a2.3,2.3,0,0,1,2.293,2.293v6.221a2.3,2.3,0,0,1-2.293,2.293H163.785a2.3,2.3,0,0,1-2.293-2.293Z" transform="translate(-139.235 -1457.751)" fill="#f9c800"/>
    <path id="Path_57" data-name="Path 57" d="M184.918,1521.383H163.48a.943.943,0,1,0,0,1.885h21.438a.943.943,0,1,0,0-1.885Z" transform="translate(-141.09 -1486.463)" fill="#f9c800"/>
    <path id="Path_58" data-name="Path 58" d="M184.918,1533.138H163.48a.943.943,0,1,0,0,1.885h21.438a.943.943,0,1,0,0-1.885Z" transform="translate(-141.09 -1493.907)" fill="#f9c800"/>
    <path id="Path_59" data-name="Path 59" d="M178.426,1544.893H163.48a.943.943,0,1,0,0,1.885h14.947a.943.943,0,0,0,0-1.885Z" transform="translate(-141.09 -1501.35)" fill="#f9c800"/>
    <path id="Path_110" data-name="Path 110" d="M176.8,1544.893H163.383a.948.948,0,0,0,0,1.885H176.8a.948.948,0,0,0,0-1.885Z" transform="translate(-110.081 -1501.35)" fill="#f9c800"/>
    <path id="Path_60" data-name="Path 60" d="M248.343,1490.621h17.116a4.183,4.183,0,0,0,4.178-4.178v-6.221a4.183,4.183,0,0,0-4.178-4.178H248.343a4.183,4.183,0,0,0-4.178,4.178v6.221A4.183,4.183,0,0,0,248.343,1490.621Zm-2.293-10.4a2.3,2.3,0,0,1,2.293-2.293h17.116a2.3,2.3,0,0,1,2.293,2.293v6.221a2.3,2.3,0,0,1-2.293,2.293H248.343a2.3,2.3,0,0,1-2.293-2.293Z" transform="translate(-192.783 -1457.751)" fill="#f9c800"/>
    <path id="Path_61" data-name="Path 61" d="M269.476,1521.383H248.038a.943.943,0,1,0,0,1.885h21.438a.943.943,0,1,0,0-1.885Z" transform="translate(-194.638 -1486.463)" fill="#f9c800"/>
    <path id="Path_62" data-name="Path 62" d="M269.476,1533.138H248.038a.943.943,0,1,0,0,1.885h21.438a.943.943,0,1,0,0-1.885Z" transform="translate(-194.638 -1493.907)" fill="#f9c800"/>
    <path id="Path_63" data-name="Path 63" d="M234.26,1576.156h-2a.943.943,0,0,0,0,1.885h2a.943.943,0,0,0,0-1.885Z" transform="translate(-184.647 -1521.149)" fill="#0af"/>
    <path id="Path_64" data-name="Path 64" d="M214.751,1487.924h-3.183v-23.863c0-3.424-3.058-6.209-6.818-6.209H149.466c-3.761,0-6.822,2.785-6.822,6.209v36.351c0,3.424,3.06,6.209,6.822,6.209h26.748v5.751H170.97a.893.893,0,0,0,0,1.785h12.272a.893.893,0,1,0,0-1.785H178v-5.751h16.634v13.588a4.912,4.912,0,0,0,4.907,4.907h15.212a4.912,4.912,0,0,0,4.907-4.907v-27.373A4.914,4.914,0,0,0,214.751,1487.924Zm-65.286,16.811c-2.722,0-4.936-1.94-4.936-4.323v-36.351c0-2.384,2.214-4.324,4.936-4.324H204.75c2.72,0,4.933,1.94,4.933,4.324v23.863H199.539c-1.549,0-3.167.392-3.927,1.435-.355.394-.98,1.1-.98,3.476v5.033H149.807a.943.943,0,0,0,0,1.885h44.825v4.982Zm68.308,15.473a3.026,3.026,0,0,1-3.022,3.022H199.539a3.025,3.025,0,0,1-3.022-3.022v-27.373a3.027,3.027,0,0,1,3.022-3.026h15.212a3.027,3.027,0,0,1,3.022,3.026Z" transform="translate(-128.493 -1446.23)" fill="#0af"/>
    <path id="Path_65" data-name="Path 65" d="M297.5,1563.589h11.3a3.252,3.252,0,0,0,3.249-3.249V1555.4a3.253,3.253,0,0,0-3.249-3.249H297.5a3.253,3.253,0,0,0-3.249,3.249v4.945A3.252,3.252,0,0,0,297.5,1563.589Zm-1.363-8.193a1.365,1.365,0,0,1,1.363-1.364h11.3a1.365,1.365,0,0,1,1.364,1.364v4.945a1.365,1.365,0,0,1-1.364,1.364H297.5a1.365,1.365,0,0,1-1.363-1.364Z" transform="translate(-224.501 -1505.944)" fill="#0af"/>
    <path id="Path_66" data-name="Path 66" d="M311.638,1590.29H297.173a.942.942,0,1,0,0,1.885h14.465a.942.942,0,1,0,0-1.885Z" transform="translate(-225.754 -1530.099)" fill="#0af"/>
    <path id="Path_67" data-name="Path 67" d="M312.58,1600.084a.943.943,0,0,0-.943-.943H297.173a.943.943,0,0,0,0,1.885h14.465A.942.942,0,0,0,312.58,1600.084Z" transform="translate(-225.754 -1535.704)" fill="#0af"/>
    <path id="Path_68" data-name="Path 68" d="M297.173,1607.993a.943.943,0,1,0,0,1.885h10.085a.943.943,0,1,0,0-1.885Z" transform="translate(-225.754 -1541.31)" fill="#0af"/>
    <path id="Path_69" data-name="Path 69" d="M310.4,1622.024H292.536a.943.943,0,0,0,0,1.885H310.4a.943.943,0,0,0,0-1.885Z" transform="translate(-222.818 -1550.195)" fill="#0af"/>
    <path id="Path_70" data-name="Path 70" d="M316.4,1628.945h-1.35a.943.943,0,0,0,0,1.885h1.35a.943.943,0,0,0,0-1.885Z" transform="translate(-237.077 -1554.578)" fill="#0af"/>
  </svg>
    ,
    title: 'UI/UX Design',
    description: "We believe that design is not just about making something look pretty, it's about creating a connection between your brand and your customers. That's why our process starts with thorough research to understand your brand identity, your vision and your target audience. This allows us to design a product that will truly resonate with your customers increasing their satisfaction and engagement which can lead to improved conversion rates, reduced development costs, and a positive brand perception.By focusing on the user, we help businesses create products that are both functional and aesthetically pleasing, resulting in better business outcomes and happier customers"

  },
  {
    id: 3,
    visible: false,
    img: <svg className="ico" xmlns="http://www.w3.org/2000/svg" width="71.681" height="79.497" viewBox="0 0 71.681 79.497">
    <g id="Group_113" data-name="Group 113" transform="translate(-75)">
      <g id="IT_Consulting_Services" data-name="IT Consulting Services" transform="translate(75)">
        <path id="Path_37" data-name="Path 37" d="M617.354,1942.148l-39.4-7.793a4.378,4.378,0,0,0-5.145,3.446l-3.562,18.005a4.384,4.384,0,0,0,3.446,5.145l1.558.308-.74,3.741a2.623,2.623,0,0,0,3.924,2.759l7.358-4.415,27.3,5.4a4.438,4.438,0,0,0,.859.085,4.381,4.381,0,0,0,4.286-3.53l3.561-18A4.383,4.383,0,0,0,617.354,1942.148Zm1.374,4.735-3.561,18.005a2.267,2.267,0,0,1-2.664,1.784l-28.1-5.558-8.056,4.834a.513.513,0,0,1-.767-.539l1.149-5.812-3.628-.718a2.27,2.27,0,0,1-1.784-2.664l3.561-18.005a2.267,2.267,0,0,1,2.664-1.784l39.4,7.793A2.27,2.27,0,0,1,618.728,1946.883Z" transform="translate(-569.162 -1889.331)" fill="#f9c800"/>
        <path id="Path_38" data-name="Path 38" d="M600.851,1824.177a1.061,1.061,0,0,0,1.467.308l.708-.462,1.526,2.337a1.061,1.061,0,0,0,1.467.308,1.1,1.1,0,0,0,.249-.223,1.066,1.066,0,0,0,.218-.481l1.094-6.165a1.051,1.051,0,0,0-.157-.768l-.065-.1a1.06,1.06,0,0,0-1.467-.309l-.708.462-1.526-2.338a1.061,1.061,0,0,0-1.932.394l-1.1,6.168a1.055,1.055,0,0,0,.157.766Zm1.946-6.71,1.482,2.271a1.061,1.061,0,0,0,1.467.308l.707-.462.027.041-1.068,6.019-1.484-2.273a1.061,1.061,0,0,0-1.467-.308l-.707.462-.027-.04Z" transform="translate(-588.845 -1815.478)" fill="#0af"/>
        <path id="Path_39" data-name="Path 39" d="M586.369,1869.21l1.95.118a.471.471,0,0,1,.4.4l.041.825a1.822,1.822,0,0,0,1.653,1.678l1.995.142.049,0a.676.676,0,0,0,.047-1.35l-1.995-.142a.474.474,0,0,1-.4-.4l-.04-.825a1.818,1.818,0,0,0-1.67-1.68l-1.949-.118a.664.664,0,0,0-.716.633A.676.676,0,0,0,586.369,1869.21Z" transform="translate(-579.533 -1847.767)" fill="#fff"/>
        <path id="Path_40" data-name="Path 40" d="M657.9,1821.448a.676.676,0,0,0,.874-.386l.892-2.3a.738.738,0,0,1,.8-.422l.988.251a2.083,2.083,0,0,0,2.4-1.262l.846-2.257a.676.676,0,1,0-1.266-.475l-.846,2.257a.735.735,0,0,1-.8.427l-.989-.251a2.087,2.087,0,0,0-2.393,1.244l-.891,2.3A.676.676,0,0,0,657.9,1821.448Z" transform="translate(-624.427 -1814.158)" fill="#f9c800"/>
        <path id="Path_41" data-name="Path 41" d="M598.807,1906.708a2.084,2.084,0,0,0-2.142,1.661.675.675,0,0,0,.541.788.662.662,0,0,0,.124.012.677.677,0,0,0,.664-.553.728.728,0,0,1,.714-.56l1.017.075a.676.676,0,1,0,.1-1.348Z" transform="translate(-586.367 -1872.078)" fill="#f9c800"/>
        <path id="Path_42" data-name="Path 42" d="M701.9,1932.942a1.664,1.664,0,0,1-1.484.31l-1.031-.405a.676.676,0,0,0-.495,1.258l1.032.405a2.49,2.49,0,0,0,.905.16,2.989,2.989,0,0,0,2.005-.75.676.676,0,1,0-.933-.978Z" transform="translate(-650.083 -1888.383)" fill="#f9c800"/>
        <path id="Path_43" data-name="Path 43" d="M675.894,1860.622l-2.034-18.236a4.378,4.378,0,0,0-4.837-3.866l-39.918,4.451a4.383,4.383,0,0,0-3.866,4.836l2.033,18.236a4.385,4.385,0,0,0,4.837,3.866l28.71-3.2,6.63,4.782a2.624,2.624,0,0,0,4.143-2.419l-.393-3.521.829-.092a4.377,4.377,0,0,0,3.866-4.837Zm-2.58,1.9a2.252,2.252,0,0,1-1.521.838l-2.927.326.627,5.619a.513.513,0,0,1-.81.473l-7.29-5.259-29.519,3.292a2.27,2.27,0,0,1-2.5-2l-2.033-18.236a2.27,2.27,0,0,1,2-2.5l39.918-4.45a2.267,2.267,0,0,1,2.5,2l2.033,18.236A2.252,2.252,0,0,1,673.315,1862.522Z" transform="translate(-604.241 -1829.387)" fill="#0af"/>
        <path id="Path_44" data-name="Path 44" d="M643.245,1862.688l34.56-3.853a1.055,1.055,0,1,0-.234-2.1l-34.56,3.853a1.056,1.056,0,0,0,.116,2.1A1.076,1.076,0,0,0,643.245,1862.688Z" transform="translate(-614.793 -1840.802)" fill="#0af"/>
        <path id="Path_45" data-name="Path 45" d="M680.606,1874.422a1.058,1.058,0,0,0-1.166-.932l-34.56,3.853a1.056,1.056,0,0,0,.116,2.1,1.044,1.044,0,0,0,.118-.007l34.56-3.853A1.055,1.055,0,0,0,680.606,1874.422Z" transform="translate(-615.962 -1851.287)" fill="#0af"/>
        <path id="Path_46" data-name="Path 46" d="M706.865,1890.213l-19.442,2.168a1.056,1.056,0,0,0,.116,2.1,1.14,1.14,0,0,0,.118-.006l19.442-2.168a1.056,1.056,0,1,0-.234-2.1Z" transform="translate(-642.589 -1861.754)" fill="#0af"/>
        <path id="Path_47" data-name="Path 47" d="M633.378,1960.182a1.054,1.054,0,0,0-1.49.1l-11.515,13.105a1.056,1.056,0,0,0,1.586,1.394l11.515-13.106A1.055,1.055,0,0,0,633.378,1960.182Z" transform="translate(-601.049 -1905.383)" fill="#f9c800"/>
        <path id="Path_48" data-name="Path 48" d="M603.952,1957.152a1.055,1.055,0,0,0-1.464-.289l-8.052,5.392,5.393,8.052a1.056,1.056,0,0,0,1.754-1.175l-4.218-6.3,6.3-4.218A1.055,1.055,0,0,0,603.952,1957.152Z" transform="translate(-584.98 -1903.358)" fill="#f9c800"/>
        <path id="Path_49" data-name="Path 49" d="M659.131,1966.341a1.056,1.056,0,0,0-.29,1.465l4.218,6.3-6.3,4.218a1.055,1.055,0,1,0,1.174,1.754l8.052-5.392-5.393-8.052A1.056,1.056,0,0,0,659.131,1966.341Z" transform="translate(-623.693 -1909.291)" fill="#f9c800"/>
      </g>
    </g>
  </svg>
  
    ,
    title: 'IT Consulting Services',
    description: "IT consulting services are critical for businesses to stay competitive and operate efficiently in today's digital landscape. At Beetee, our team of experienced consultants provides comprehensive IT consulting services to help businesses optimize their technology infrastructure, minimize risk, and achieve their goals. We have a deep understanding of the IT industry and stay up-to-date with the latest trends and technologies to provide our clients with the most effective solutions. Our IT consulting services are customized to meet the unique needs of each client, from small businesses to large enterprises. Whether you need guidance on IT strategy, cloud migration, security, or other technology-related challenges, our team can provide the expert advice and support you need to succeed."

  },
]