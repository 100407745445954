import { useState,useEffect } from "react";

export const  useActiveId = ()=>{
    const [activeId, setActiveId] = useState('home');

    useEffect(() => {
      const handleScroll = () => {
        const sections = document.querySelectorAll('section');
        const scrollTop = window.pageYOffset;
       
        
    
        sections.forEach(section => {
          if (section.offsetTop <= scrollTop + 70) {
            setActiveId(section.id);
            
    
            
          }
        });
      };
    

    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    return activeId    
 
}