import "./style.scss";
import logo from "../../assets/images/flesh.png";
import { useEffect } from "react";
import 'animate.css'
import WOW from 'wowjs';


function Landing () {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (

     <section    className="L-landing-container G-position-sticky section sizingss animate__animated animate__fadeIn G-disable-sticky" id="home">
      
       <div className="G-container  G-flex G-justify-center G-align-center  G-text-align ">
       
         <div className="L-landing-wrapper G-flex G-justify-center G-align-center G-flex-column">
           <div className="L-logo-cont G-flex G-justify-center G-align-center G-flex-column">
             <div className="L-logo-box ">
             <svg xmlns="http://www.w3.org/2000/svg" className="flas-icon"><path   d="M20.608 12.463v.34c0 .366-.134.719-.388.987l-2.344 2.499-9.922 10.565a1.428 1.428 0 0 1-.628.388c-.134.05-.276.07-.424.07h-.007c-.797 0-1.44-.649-1.44-1.44v-8.144c0-.797-.642-1.44-1.44-1.44H1.44c-.79 0-1.44-.642-1.44-1.44v-.338c0-.367.141-.72.395-.988l2.35-2.498 2.979-3.17L12.668.46c.275-.29.65-.459 1.052-.459.79 0 1.44.65 1.44 1.44v8.144c0 .797.642 1.44 1.44 1.44h2.568c.797 0 1.44.642 1.44 1.44Z" fill="#f9c800" fillRule="evenodd" data-name="Path 6"/></svg>
             </div>
             
           </div>
           <div className="L-title-container">
           <p className="Pages-greeting">Welcome to Beetee</p>
             <div className="L-title-cont">
               <h2 className="Pages-Title">IT Services & Consulting</h2>
             </div>
             <div className="L-page-description">
               <p className="Pages-description">From technology strategy to implementation, we're here to help your business succeed.</p>
             </div>
           </div>
         </div>
       </div>
     </section>

  );
}

export default Landing;