import './style.scss';
import { useMiddleActiveId } from '../../hook/useMiddleActiveId';

function MyScroll() {
    const middleId = useMiddleActiveId()
    function progressBarScroll() {
        let winScroll = document.body.scrollTop || document.documentElement.scrollTop,
            height = document.documentElement.scrollHeight - document.documentElement.clientHeight,
            scrolled = (winScroll / height) * 100;
        document.getElementById("progressBar").style.height = scrolled + "%";
      }
      
      window.onscroll = function () {
        progressBarScroll()
      };

      let mystyle;
   
      if(middleId === 'home' ||  middleId === 'contact'|| middleId === 'service' || middleId === 'about'|| middleId === 'inside' || middleId === 'Blog'){
        mystyle = {backgroundColor:'#00AAFF'}
      }
      else if( middleId === 'news'){
        mystyle = {backgroundColor:'snow'}

      }
      else{
        mystyle = {backgroundColor:'#00AAFF'}
      }

    return (
        <div className="L-scroll">
           
                <ul className='L-ul G-flex G-flex-column' id='progressBar' style={mystyle} >

                </ul>
        </div>
    )
}
export default MyScroll;