import { useState } from 'react';
import './index.scss';
import close from '../../assets/images/headerImages/close.svg';

function Modal({onClick,src,content,onButtonClick,title}){
    const [modal,setModal] = useState(false);
    const [state,setState] = useState(true)
    const handleclick = () =>{
        setState(!state)
    }
    const handleButtonClick = () =>{
        onButtonClick()
        setState(!state)
    }
    return(
        <div className="modal G-flex G-justify-center G-align-center" onClick={onClick}>           
            <div className="overviev G-flex G-justify-center G-align-center" onClick={()=> handleclick()}>
            <div className={state ? "popup G-flex G-justify-evenly G-align-center G-flex-column" : "popupClose G-flex G-justify-evenly G-align-center G-flex-column"} onClick={(event)=>event.stopPropagation()} >
                <button onClick={handleButtonClick} className='closebutton' > <img src={close} alt="" className='close-svg' /> </button>
                  <div className="icon-cont">  <p  className='none' >{src}</p> <h2 style={{marginLeft:'5%'}}>{title}</h2></div>
                    <div className='content-div'><p>{content}</p></div>
            </div>
            </div>
        </div>
    )

}

export default Modal;