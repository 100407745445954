import { useState,useEffect } from "react";

export const  useMiddleActiveId = ()=>{
    const [middleActiveId, setActiveId] = useState('');

    useEffect(() => {
      const handleScroll = () => {
        const sections = document.querySelectorAll('section');
        const scrollTop = window.pageYOffset;
    
        sections.forEach(section => {
       
          if (section.offsetTop - section.offsetHeight +695 <= scrollTop  ) {
            setActiveId(section.id);
          }
        });
      };
    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    return middleActiveId    
}